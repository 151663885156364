const platformPermissionsTooltip = {
  TW: {
    Inventory: {
      Catalog: [
        'Access/View: ability to access and utilize the catalog menu functionalities'
      ],
      ToolModel: [
        'Access/View: access to view any tool model record in the catalog',
        'Add/Edit: ability to create new tool model records and edit fields in the item\'s tool model record',
        'Delete: ability to delete a tool model record'
      ],
      MaterialModel: [
        'Access/View: access to view any material model record in the catalog',
        'Add/Edit: ability to create new material model records and edit fields in the item\'s material model record',
        'Delete: ability to delete a material model record'
      ],
      Components: [
        'Access/View: ability to view the associated components on all of the tool model\'s tool records',
        'Add/Edit: ability to add and edit associated tools you are tracking in the platform with this model record',
        'Add/Edit: ability to delete components from a tool\'s model record, without deleting tool model records'
      ],
      DataSource: [
        'Access/View: ability to view the tool models that are stored in the Online Datasource',
        'Add/Edit: ability to import tool models from the Online Datasource into your company\'s'
      ],
      RepairParts: [
        'Access/View: ability to view the repair parts that have been associated with a specific model record',
        'Add/Edit: ability to add, edit the required quantity, and associate any of your materials & consumables as repair parts for a specific model record',
        'Delete: ability to remove any material & consumables from the repair parts tab in a specific model',
        'Perform: ability to add repair parts under service > repair parts in product detail screen'
      ],
      ItemBrowser: [
        'Access/View: ability to access and utilize the item browser menu functionalities'
      ],
      ToolDetail: [
        'Access/View: access to view any tool record in the item browser',
        'Add/Edit: ability to create new tool records, add inventory and edit fields in the item\'s tool record',
        'Delete: ability to delete a tool record'
      ],
      MaterialDetail: [
        'Access/View: access to view any material record in the item browser',
        'Add/Edit: ability to create new material records, add inventory and edit fields in the item\'s material record',
        'Delete: ability to delete a material record'
      ],
      AdjustInventory: [
        'Perform: ability to adjust the quantity of tools present at a particular assignment'
      ],
      Kits: [
        'Access/View: ability to view the contents of a kit in the item browser',
        'Add/Edit: ability to create new kits and change the contents of a kit in the item browser',
        'Delete: ability to delete kits'
      ],
      RetireTool: [
        'Perform: ability to retire an asset and remove it from the item browser to the Manage Retired Tools page'
      ],
      StockpointSpecific: [],
      LowLevelBrowser: [
        'Access/View: ability to view tools and materials found in the low level browser'
      ],
      ManageRetiredTools: [
        'Access/View: ability to browse, search and view retired tools'
      ]
    },
    InventoryMovement: {
      PickTicket: [
        'Access/View: ability to view open or closed pick tickets',
        'Add/Edit: ability to create, open and edit existing pick tickets in the draft status ',
        'Delete: ability to delete an open pick ticket',
        'Perform: ability to pick, close and transfer picked items from a pick ticket'
      ],
      Transfers: [
        'Access/View: ability to search for and view closed transfer tickets',
        'Delete: ability to delete closed transfer tickets',
        'Perform: ability to create a transfer ticket and ability to use the process transfer button in an open transfer ticket'
      ],
      CountInventory: [
        'Access/View: ability to view the inventory adjustments and browse or search for open or closed count tickets',
        'Add/Edit: ability to create, open and edit existing count tickets',
        'Delete: ability to delete an existing count ticket',
        'Perform: ability to count items in a count ticket and ability to post adjustments in the inventory'
      ],
      RequisitionsandReturns: [
        'Access/View: ability to access the requisitions and returns menu page',
        'Add/Edit: ability to create, open and edit existing requisitions and returns',
        'Delete: ability to delete an open requisition or return'
      ],
      ShowModelRates: [
        'Access/View: ability to access the requisitions rates'
      ],
      ShowModelQuantities: [
        'Access/View: ability to access the requisitions stock quantities'
      ],
      RequisitionBrowser: [
        'Access/View: Ability to view the Requisition Browser and Requisitions in the browser. Add/Edit: Ability to Edit Requisitions. Delete: Ability to delete Requisitions.'
      ]
    },
    'Service&Calibration': {
      ServiceBrowser: [
        'Access/View: ability to access and utilize the service browser menu functionalities'
      ],
      ServiceLog: [
        'Access/View: ability to view open or closed service requests',
        'Add/Edit: ability to create and edit existing service requests in the pending status',
        'Delete: ability to delete an open service request',
        'Perform: ability to convert a service request into a work order'
      ],
      ServiceEvent: [
        'Access/View: ability to view existing service events',
        'Add/Edit: ability to create and edit existing service events',
        'Delete: ability to delete service events',
        'Perform: ability to save a new service event and the ability to add event from model'
      ],
      ServiceTicket: [
        'Access/View: ability to view open or closed work orders',
        'Add/Edit: ability to create new and edit existing work orders',
        'Delete: ability to delete work orders',
        'Perform: ability to save and close a work order'
      ],
      ServiceSchedule: [
        'Access/View: ability to view open or scheduled events',
        'Add/Edit: ability to create new and edit existing Service Schedules',
        'Delete: ability to delete Service Schedules',
        'Perform: ability to save and close Service Schedules'
      ]
    },
    Purchasing: {
      PurchaseOrders: [
        'Access/View: ability to view open or closed purchase orders',
        'Add/Edit: ability to create new and edit existing purchase orders in draft status',
        'Delete: ability to delete open or closed purchase orders',
        'Perform: ability to use the receive purchase order button'
      ],
      POTicket: [
        'Access/View: ability to open purchase orders to view their details',
        'Add/Edit: ability to create new purchase orders in the draft status',
        'Delete: ability to delete purchase orders',
        'Perform: ability to save a purchase order'
      ],
      ProductVendors: [],
      PricingPlans: [],
      ReceivingTickets: [
        'Access/View: ability to view closed receiving tickets'
      ],
      ReceivingTicket: [
        'Access/View: ability to view an open or closed recieving ticket and the ability to open the purchase order, associated with the receiving ticket',
        'Add/Edit: ability to receive against a purchase order, create a new receiving ticket, and edit an open receiving ticket',
        'Perform: ability to recieve items into your inventory'
      ]
    },
    'JobCost&Billing': {
      'Billing&InvoiceBrowser': [
        'Access/View: ability to access and utilize the job cost & billing menu functionalities'
      ],
      BillingBrowser: [
        'Access/View: ability to open and see the billing browser page'
      ],
      'CalculateJobCost&Billing': [
        'Perform: ability to use the calculate button to calculate un-posted charges'
      ],
      'MiscCharge/Credit': [
        'Access/View: ability to see existing un-posted miscellaneous charges or credits',
        'Add/Edit: ability to create new and edit existing un-posted miscellaneous charges or credits',
        'Delete: ability to delete existing un-posted miscellaneous charges or credits'
      ],
      'PostJobCost&Billing': [
        'Perform: ability to post existing un-posted charges'
      ],
      RateSheets: [
        'Access/View: ability to see existing materials & consumables and tools & equipment rate sheets',
        'Add/Edit: ability to create new and edit existing materials & consumables and tools & equipment rate sheets',
        'Delete: ability to delete existing materials & consumables and tools & equipment rate sheets'
      ],
      CompanyCalendar: [
        'Access/View: ability to open and see the company calendar page',
        'Add/Edit: ability to edit the company calendar'
      ],
      'Calendar-CostCenter': [
        'Access/View: ability to open and see the company calendar page',
        'Add/Edit: ability to edit the company calendar'
      ],
      HourlyUsageLog: [
        'Access/View: ability to open and see equipment usage log',
        'Add/Edit: ability to add tools & equipment to the equipment usage log and edit lines in the equipment usage log',
        'Delete: ability to delete tools & equipment from the equipment usage log'
      ],
      'ExportJobCost&Billing': [
        'Perform: ability to use the export data button in the job cost & billing module'
      ]
    },
    Scheduling: {
      SchedulingRate: [
        'Access/View: the rate for a model when requesting scheduling'
      ],
      SchedulingAvailability: [
        'Access/View: the availability for a model when requesting scheduling'
      ],
      SchedulingBrowser: [
        'Access/View: the availability for a model when requesting scheduling'
      ],
      SchedulingCalendar: [
        'Access/View: the availability for a model when requesting scheduling'
      ],
      SchedulingRequest: [
        'Access/View: the availability for a model when requesting scheduling'
      ]
    },
    'Reports&Graphs': {
      Reports: [
        'Access/View: access to generate / download various reports and apply filters to capture unique tool data'
      ],
      ToolReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Tool reports'
      ],
      ListReports: [
        'Access/View: ability to run, print, PDF, and/or export data for List reports'
      ],
      AssignmentReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Transfer reports'
      ],
      BarCodeLabels: [
        'Access/View: ability to run, print, PDF, and/or export data for the Bar Code Label reports'
      ],
      ConsumableReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Consumable reports'
      ],
      PurchasingReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Purchasing reports'
      ],
      ServiceReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Service reports'
      ],
      BillingReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Billing reports'
      ],
      TasksReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Tasks reports'
      ],
      FuelReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Fuel reports'
      ],
      GraphReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Graph reports'
      ],
      CustomReports: [
        'Access/View: ability to view and generate custom list reports',
        'Delete: ability to delete existing custom list reports'
      ],
      ScheduledReports: [
        'Access/View: ability to view the scheduled reports page menu',
        'Add/Edit: ability to add new and edit existing scheduled reports for assigned users with a determined email frequency for specific reports',
        'Delete: ability to delete existing scheduled reports'
      ],
      CustomDashboards: []
    },
    Tracking: {
      LocationMap: [
        'Access/View: ability to view the Location Map'
      ],
      FuelCards: [
        'Access/View: ability to access Fuel Cards from the navigation menu'
      ],
      WirelessGateways: [
        'Access/View: ability to access and view wireless gateways',
        'Add/Edit: ability to add new or edit existing wireless gateways',
        'Delete: ability to delete wireless gateways'
      ],
      WirelessTags: [
        'Access/View: ability to access and view wireless tags',
        'Add/Edit: ability to add new or edit existing wireless tags',
        'Delete: ability to delete wireless tags'
      ]
    },
    ToolWatchSettings: {
      Categories: [
        'Access/View: access to view existing tool, material, or template categories in settings list page',
        'Add/Edit: ability to create and manage tool, material or template categories',
        'Delete: ability to delete tool, material or template categories'
      ],
      Classes: [
        'Access/View: access to view existing classes in settings list page',
        'Add/Edit: ability to create and manage classes',
        'Delete: ability to delete classes'
      ],
      StatusCodes: [
        'Access/View: access to view existing status codes in settings list page',
        'Add/Edit: ability to create and manage status codes',
        'Delete: ability to delete status codes'
      ],
      ShippingMethods: [
        'Access/View: access to view existing shipping methods in settings list page',
        'Add/Edit: ability to create and manage shipping methods',
        'Delete: ability to delete shipping methods'
      ],
      UnitsofMeasure: [
        'Access/View: access to view existing units of measure in settings list page',
        'Add/Edit: ability to create and manage units of measure',
        'Delete: ability to delete units of measure'
      ],
      Vendors: [
        'Access/View: access to view existing vendors in settings list page',
        'Add/Edit: ability to create and manage vendors',
        'Delete: ability to delete vendors'
      ],
      CostCenters: [
        'Access/View: access to view existing Cost Centers in settings list page',
        'Add/Edit: ability to create and manage Cost Centers',
        'Delete: ability to delete Cost Centers'
      ],
      CostCodes: [
        'Access/View: access to view existing cost codes in settings list page',
        'Add/Edit: ability to create and manage cost codes',
        'Delete: ability to delete cost codes'
      ],
      DataMergeUtility: [
        'Perform: ability to merge data that is available in the Data Merger window'
      ]
    }
  },
  EHS: {
    Inspections: {
      InspectionMaintenance: [
        'Access/View: access to completed and in-progress inspection records'
      ],
      JobBankMaintenance: [
        'Access/View: access to job bank job inspection records'
      ],
      StandardChecklistMaintenance: [
        'Access/View: access to standard EHS checklists, categories and items'
      ],
      CustomChecklistMaintenance: [
        'Access/View: access to custom checklists, categories and items'
      ],
      CorrectiveActionTracking: [
        'Access/View: access to corrective action item detials for all inspections'
      ],
      RemoteSessions: [
        'Access/View: access to VSR remote inspection records'
      ],
      InspectionSettings: [
        'Access/View: access to custom user-defined settings and cover letter management'
      ]
    },
    Training: {
      TrainingEvents: [
        'Access/View: access to create and manage scheduled, in-progress and completed training records'
      ],
      TrainingTracking: [
        'Access/View: access to manage required trainings and the tracking of users assigned and their last training date'
      ],
      ToolBoxTalks: [
        'Access/View: access to where custom toolboxtalks are uploaded'
      ],
      TrainingHistory: [
        'Access/View: access to view full history of scheduled, in-progress and completed training records'
      ],
      RequiredTrainings: [
        'Access/View: access to manage required training records'
      ],
      TrainingExam: [
        'Access/View: access to create and manage toolboxtalk training exams'
      ],
      TrainingConfirmation: [
        'Access/View: access to create and manage training confirmation event records'
      ],
      TrainingSettings: [
        'Access/View: access to training module permission settings'
      ]
    },
    Incidents: {
      IncidentMaintenance: [
        'Access/View: access to manage incident records, conduct incident investigations and create corrective action items'
      ],
      OSHA301: [
        'Access/View: access to create and manage OSHA 301 recordables from scratch or existing incident records'
      ],
      OSHA300Log: [
        'Access/View: access to create, manage and produce company OSHA 300 logs and the OSHA 301 recordables included'
      ],
      BehaviorMaintenance: [
        'Access/View: access to create and manage incident behavior options that are visible to module end-users'
      ],
      ConditionsMaintenance: [
        'Access/View: access to create and manage incident condition options that are visible to module end-users'
      ],
      EntityMaintenance: [
        'Access/View: access to create and manage company entities tied to OSHA 300 logs'
      ],
      IncidentSettings: [
        'Access/View: access to incident module and admin permission settings'
      ]
    },
    Observations: {
      ObservationMaintenance: [
        'Access/View: access to manage observation records and responsible party corrective action items'
      ],
      CategoryMaintenance: [
        'Access/View: access to create and manage observation category options that are visible to module end-users'
      ],
      ContributingFactorMaintenance: [
        'Access/View: access to create and manage observation contributing factor options that are visible to module end-users'
      ],
      ObservationSettings: [
        'Access/View: access to observation module and admin permission settings'
      ]
    },
    JobSafetyAnalysis: {
      JSAMaintenance: [
        'Access/View: access to create and manage Job Safety Analysis forms, with its unique tasks, hazards, and controls'
      ],
      JSABankMaintenance: [
        'Access/View: access to create and manage JSA Bank form templates, with its unique tasks, hazards, and controls for users to access in the field'
      ],
      HazardsMaintenance: [
        'Access/View: access to create and manage JSA hazard options that are visible to module end-users'
      ],
      ControlsMaintenance: [
        'Access/View: access to create and manage JSA control options that are visible to module end-users'
      ],
      ClassificationMaintenance: [
        'Access/View: access to create and manage JSA classification options that are visible to module end-users'
      ],
      PPEMaintenance: [
        'Access/View: access to create and manage JSA PPE options that are visible to module end-users'
      ],
      RequiredTrainingMaintenance: [
        'Access/View: access to create and manage JSA required training options that are visible to module end-users'
      ],
      ChemicalMaintenance: [
        'Access/View: access to create and manage JSA hazards options that are visible to module end-users'
      ],
      JSASettings: [
        'Access/View: access to JSA module permission settings'
      ]
    },
    SafetyScan: {
      Assets: [
        'Access/View: access to create and manage safety scan assets that are available to module end-users to inspect'
      ],
      AssetTypes: [
        'Access/View: access to create and manage asset types that group assets and are assigned to checklists'
      ],
      Checklists: [
        'Access/View: access to create and manage checklist questions for a specific asset type that are available to module end-users'
      ],
      GenerateQRCodes: [
        'Access/View: access to create and manage unique QR codes for all assets. Produce QR code sheets and determine QR code size and branding'
      ],
      Overview: [],
      ScanCorrectiveActionTracking: [
        'Access/View: access to corrective action item detials for all asset inspections'
      ],
      Routes: [
        'Access/View: access to create and manage routes that determine order to inspect multiple assets'
      ],
      Locations: [],
      'Building,Floor&Area': [
        'Access/View: access to create and manage detailed information of where each individual asset is placed'
      ],
      Zone: [
        'Access/View: access to create and manage equipment zones tied to each asset and route'
      ],
      Division: [
        'Access/View: access to create and manage equipment divisions tied to each asset'
      ],
      SafetyScanSettings: [
        'Access/View: access to Safety Scan module permission settings'
      ]
    },
    Forms: {
      FormsMaintenance: [
        'Access/View: access to create and manage form checklists and questions that form users can complete in the field'
      ],
      CompletedForms: [
        'Access/View: access to view and download all completed forms by module end-users'
      ],
      FormsSettings: [
        'Access/View: access to Forms module permission settings'
      ]
    },
    'EHSReports&Graphs': {
      PerformanceReports: [
        'Access/View: access to generate / download excel reports and apply filters to capture unique safety data'
      ],
      InspectionPerformanceReports: [
        'Access/View: access to generate / download various inspection module reports and apply filters to capture unique inspection data'
      ],
      TrainingPerformanceReports: [
        'Access/View: access to generate / download various training module reports and apply filters to capture unique training data'
      ],
      IncidentsPerformanceReports: [
        'Access/View: access to generate / download various incident module reports and apply filters to capture unique incident data'
      ],
      ObservationsPerformanceReports: [
        'Access/View: access to generate / download various observation module reports and apply filters to capture unique observation data'
      ],
      JSAPerformanceReports: [
        'Access/View: access to generate / download various JSA module reports and apply filters to capture unique JSA data'
      ],
      SafetyScanPerformanceReports: [
        'Access/View: access to generate / download various safety scan module reports and apply filters to capture unique safety scan data'
      ],
      FormsPerformanceReports: [
        'Access/View: access to generate / download various forms module reports and apply filters to capture unique forms data'
      ],
      EHSScheduledReports: [
        'Access/View: access to create and manage scheduled email reports to designated recipients from saved performance report data'
      ],
      InspectionScheduledReports: [
        'Access/View: access to create and manage scheduled email reports to designated recipients from saved inspection report data'
      ],
      TrainingScheduledReports: [
        'Access/View: access to create and manage scheduled email reports to designated recipients from saved training report data'
      ],
      IncidentsScheduledReports: [
        'Access/View: access to create and manage scheduled email reports to designated recipients from saved incidents report data'
      ],
      ObservationsScheduledReports: [
        'Access/View: access to create and manage scheduled email reports to designated recipients from saved observations report data'
      ],
      JSAScheduledReports: [
        'Access/View: access to create and manage scheduled email reports to designated recipients from saved JSA report data'
      ],
      SafetyScanScheduledReports: [
        'Access/View: access to create and manage scheduled email reports to designated recipients from saved safety scan report data'
      ]
    },
    EHSSettings: {
      AccountSettings: [
        'Access/View: access to manage account info and the account\'s report logo'
      ],
      BillingInformation: [
        'Access/View: access to manage the account\'s billing info, main contact, and credit card payment information'
      ],
      SiteSafetyPlans: [
        'Access/View: access to create and manage project specific safety plans that contain safety requirements, job hazards, site stakeholders, contractors and PPE requirements for a job\'s duration',
        'Add/Edit: ability to add new site safety plans and update any existing plan information',
        'Delete: ability to delete existing site safety plans from the plan list'
      ],
      ProcoreIntegration: [
        'Access/View: access to authorize and sync the EHS account to a Procore account. When successful, Procore jobs are integrated in platform'
      ],
      PlangridIntegration: [
        'Access/View: access to authorize and sync the EHS account to a Plangrid account. When successful, Plangrid jobs are integrated in platform'
      ],
      EHSLicenseMaintenance: [
        'Access/View: access to view and manage all module end-users who have authorization to use the EHS mobile and web app'
      ],
      InspectionLicenseMaintenance: [
        'Access/View: access to view and manage all inspection module end-users who have authorization to use the mobile and web app'
      ],
      TrainingLicenseMaintenance: [
        'Access/View: access to view and manage all training module end-users who have authorization to use the mobile app'
      ],
      IncidentLicenseMaintenance: [
        'Access/View: access to view and manage all incident module end-users who have authorization to use the mobile and web app'
      ],
      ObservationLicenseMaintenance: [
        'Access/View: access to view and manage all observation module end-users who have authorization to use the mobile and web app'
      ],
      JSALicenseMaintenance: [
        'Access/View: access to view and manage all JSA module end-users who have authorization to use the mobile and web app'
      ],
      SafetyScanLicenseMaintenance: [
        'Access/View: access to view and manage all safety scan module end-users who have authorization to use the mobile and web app'
      ],
      FormsLicenseMaintenance: [
        'Access/View: access to view and manage all forms module end-users who have authorization to use the mobile and web app'
      ],
      CompanyMaintenance: [
        'Access/View: access to create and manage all companies that are tied to safety records'
      ],
      LocationMaintenance: [
        'Access/View: access to create and manage all locations that are tied to safety records'
      ],
      ObservationLocationMaintenance: [
        'Access/View: access to create and manage locations that are tied to observation records'
      ],
      IncidentsLocationMaintenance: [
        'Access/View: access to create and manage locations that are tied to incident records'
      ],
      SafetyScanLocationMaintenance: [
        'Access/View: access to create and manage locations that are tied to safety scan assets'
      ],
      FormsLocationMaintenance: [
        'Access/View: access to create and manage locations that are tied to form records'
      ],
      ResponsiblePartyMaintenance: [
        'Access/View: access to create and manage a list of responsible parties who are tied to locations or jobs and can be assigned corrective action items'
      ],
      InspectionRPMaintenance: [
        'Access/View: access to create and manage a list of inspection responsible parties who are tied to inspections or job banks and can be assigned corrective action items'
      ],
      IncidentsRPMaintenance: [
        'Access/View: access to create and manage a list of incident responsible parties who are tied to locations and can be assigned corrective action items'
      ],
      ObservationRPMaintenance: [
        'Access/View: access to create and manage a list of observation responsible parties who are tied to locations and can be assigned corrective action items'
      ],
      SafetyScanRPMaintenance: [
        'Access/View: access to create and manage a list of safety scan responsible parties who are tied to locations and can be assigned corrective action items'
      ],
      FormsRPMaintenance: [
        'Access/View: access to create and manage a list of forms responsible parties who are tied to locations and can be assigned corrective action items'
      ],
      'DataExport/API': [
        'Access/View: access to create data export admins and generate web service methods to safety data in JSON format'
      ],
      'InspectionDataExport/API': [
        'Access/View: access to create data export admins and generate web service methods to pull inspection data in JSON format'
      ],
      'TrainingDataExport/API': [
        'Access/View: access to create data export admins and generate web service methods to pull training data in JSON format'
      ],
      'IncidentDataExport/API': [
        'Access/View: access to create data export admins and generate web service methods to pull incident data in JSON format'
      ],
      'ObservationsDataExport/API': [
        'Access/View: access to create data export admins and generate web service methods to pull observation data in JSON format'
      ],
      'JSADataExport/API': [
        'Access/View: access to create data export admins and generate web service methods to pull JSA data in JSON format'
      ],
      'SafetyScanDataExport/API': [
        'Access/View: access to create data export admins and generate web service methods to pull safety scan data in JSON format'
      ],
      'FormsDataExport/API': [
        'Access/View: access to create data export admins and generate web service methods to pull forms data in JSON format'
      ]
    }
  },
  PLATFORM: {
    PlatformSettings: {
      CompanySettings: [
        'Access/View: ability to open and see the account\'s logo, tool & equipment aging periods, login requirements and other account settings'
      ],
      MapInventorySummary: [
        'Access/View: Summary of inventory at locations on Location Map'
      ],
      CompanyDefaults: [],
      Roles: [
        'Access/View: ability to open and see the roles management list page',
        'Add/Edit: ability to add new and edit existing roles with their associated users and permissions',
        'Delete: ability to delete roles if there are no users assigned to the role'
      ],
      DocumentCentral: [
        'Access/View: ability to open and see the document central repository',
        'Add/Edit: ability to add new and edit existing files or folders in the document repository',
        'Delete: ability to delete files or folders and move them to the document central recycle bin'
      ],
      DataLoader: [
        'Access/View: ability to access the data loader page menu and data loader actions',
        'Perform: ability to create a new data loader action by exporting data records and generating a template'
      ],
      CustomFields: [
        'Access/View: ability to access the custom fields page menu',
        'Add/Edit: ability to add new and edit existing custom fields',
        'Delete: ability to delete existing custom fields'
      ],
      Tasks: [],
      Workflows: [],
      Collaborate: [
        'Access/View: ability to open and view Collaborate messages',
        'Add/Edit: ability to add and edit messages in Collaborate'
      ],
      NumberBlocks: [
        'Access/View: ability to open and see the numbering page',
        'Add/Edit: ability to add and edit data in the number fields'
      ],
      GlobalListChanges: [],
      GlobalStockpointChanges: [],
      ReinstateTool: [
        'Perform: ability to reinstate tools, equipment and materials back into the item browser'
      ],
      ListVisibility: [
        'Access/View: access to view and manage stock locations to grant visibility with specific list selections'
      ],
      RecyclingBin: [
        'Access/View: access to view the contents in the recycle bin tab',
        'Add/Edit: ability to delete tools and add to the recycle bin, and also viewing recylce bin item details',
        'Delete: ability to permanently delete items in the recycle bin'
      ]
    },
    ListMaintenance: {
      Certifications: [
        'Access/View: access to view existing certifications and their renewal frequency in company list page',
        'Add/Edit: ability to create and manage certification info and its renewal frequency',
        'Delete: ability to delete certifications'
      ],
      Contractors: [
        'Access/View: access to view existing contractor info in company list page',
        'Add/Edit: ability to create and manage contractor info',
        'Delete: ability to delete contractors'
      ],
      Customers: [
        'Access/View: access to view existing customer info in company list page',
        'Add/Edit: ability to create and manage customer info',
        'Delete: ability to delete customers'
      ],
      Departments: [
        'Access/View: access to view existing departments assigned to employees in company list page',
        'Add/Edit: ability to create and manage department info and its supervisor',
        'Delete: ability to delete departments'
      ],
      Descriptions: [
        'Access/View: access to view existing description info in company list page',
        'Add/Edit: ability to create and manage description info',
        'Delete: ability to delete descriptions'
      ],
      Employees: [
        'Access/View: access to view existing employees and platform users in company list page',
        'Add/Edit: ability to create and manage employees info',
        'Delete: ability to delete employees'
      ],
      ManagePlatformUsers: [
        'Access/View: access to view Create and Manage User Account buttons in the employee info page',
        'Add/Edit: ability to create and manage platform users from existing employees'
      ],
      EmployeeTypes: [
        'Access/View: access to view existing employee types assigned to employees in company list page',
        'Add/Edit: ability to create and manage employee type info',
        'Delete: ability to delete employee types'
      ],
      Locations: [
        'Access/View: access to view existing stock and non-stock (job) locations in company list page',
        'Add/Edit: ability to create and manage location info',
        'Delete: ability to delete locations'
      ],
      StockpointDefault: [
        'Access/View: access to view the content in the org tree location dropdown',
        'Add/Edit: ability to create and manage stock locations for the org tree dropdown',
        'Delete: ability to delete stock locations ffrom the org tree dropdown'
      ],
      Manufacturers: [
        'Access/View: access to view existing manufacturer info in company list page',
        'Add/Edit: ability to create and manage manufacturer info',
        'Delete: ability to delete manufacturers'
      ]
    },
    UserSettings: {
      UserPerferences: [
        'Access/View: access to view settings page in the user profile dropdown'
      ],
      ResetPassword: [
        'Perform: ability to change your password in the user settings page'
      ],
      ManagePlatformUsers: [
        'Access/View: access to view Create and Manage User Account buttons in the employee info page',
        'Add/Edit: ability to create and manage platform users from existing employees',
        'Delete: ability to convert platform users back to employees'
      ]
    }
  },
  EAM: {
    Inventory: {
      Catalog: [
        'Access/View: ability to access and utilize the catalog menu functionalities'
      ],
      ToolModel: [
        'Access/View: access to view any tool model record in the catalog',
        'Add/Edit: ability to create new tool model records and edit fields in the item\'s tool model record',
        'Delete: ability to delete a tool model record'
      ],
      MaterialModel: [
        'Access/View: access to view any material model record in the catalog',
        'Add/Edit: ability to create new material model records and edit fields in the item\'s material model record',
        'Delete: ability to delete a material model record'
      ],
      Components: [
        'Access/View: ability to view the associated components on all of the tool model\'s tool records',
        'Add/Edit: ability to add and edit associated tools you are tracking in the platform with this model record',
        'Add/Edit: ability to delete components from a tool\'s model record, without deleting tool model records'
      ],
      DataSource: [
        'Access/View: ability to view the tool models that are stored in the Online Datasource',
        'Add/Edit: ability to import tool models from the Online Datasource into your company\'s'
      ],
      RepairParts: [
        'Access/View: ability to view the repair parts that have been associated with a specific model record',
        'Add/Edit: ability to add, edit the required quantity, and associate any of your materials & consumables as repair parts for a specific model record',
        'Delete: ability to remove any material & consumables from the repair parts tab in a specific model',
        'Perform: ability to add repair parts under service > repair parts in product detail screen'
      ],
      ItemBrowser: [
        'Access/View: ability to access and utilize the item browser menu functionalities'
      ],
      ToolDetail: [
        'Access/View: access to view any tool record in the item browser',
        'Add/Edit: ability to create new tool records, add inventory and edit fields in the item\'s tool record',
        'Delete: ability to delete a tool record'
      ],
      MaterialDetail: [
        'Access/View: access to view any material record in the item browser',
        'Add/Edit: ability to create new material records, add inventory and edit fields in the item\'s material record',
        'Delete: ability to delete a material record'
      ],
      AdjustInventory: [
        'Perform: ability to adjust the quantity of tools present at a particular assignment'
      ],
      Kits: [
        'Access/View: ability to view the contents of a kit in the item browser',
        'Add/Edit: ability to create new kits and change the contents of a kit in the item browser',
        'Delete: ability to delete kits'
      ],
      RetireTool: [
        'Perform: ability to retire an asset and remove it from the item browser to the Manage Retired Tools page'
      ],
      StockpointSpecific: ['Access/View: ability to browse, search and view stock ponts'],
      LowLevelBrowser: [
        'Access/View: ability to view tools and materials found in the low level browser'
      ],
      ManageRetiredTools: [
        'Access/View: ability to browse, search and view retired tools'
      ],
      JobStock: ['Access/View: ability to view the Job Stock',
        'Add/Edit: ability to add and edit models in Job Stock',
        'Delete: ability to delete models from Job Stock'
      ]
    },
    InventoryMovement: {
      PickTicket: [
        'Access/View: ability to view open or closed pick tickets',
        'Add/Edit: ability to create, open and edit existing pick tickets in the draft status ',
        'Delete: ability to delete an open pick ticket',
        'Perform: ability to pick, close and transfer picked items from a pick ticket'
      ],
      Transfers: [
        'Access/View: ability to search for and view closed transfer tickets',
        'Delete: ability to delete closed transfer tickets',
        'Perform: ability to create a transfer ticket and ability to use the process transfer button in an open transfer ticket'
      ],
      CountInventory: [
        'Access/View: ability to view the inventory adjustments and browse or search for open or closed count tickets',
        'Add/Edit: ability to create, open and edit existing count tickets',
        'Delete: ability to delete an existing count ticket',
        'Perform: ability to count items in a count ticket and ability to post adjustments in the inventory'
      ],
      RequisitionsandReturns: [
        'Access/View: ability to access the requisitions and returns menu page',
        'Add/Edit: ability to create, open and edit existing requisitions and returns',
        'Delete: ability to delete an open requisition or return'
      ],
      ShowModelRates: [
        'Access/View: ability to access the requisitions rates'
      ],
      ShowModelQuantities: [
        'Access/View: ability to access the requisitions stock quantities'
      ],
      RequisitionBrowser: [
        'Access/View: Ability to view the Requisition Browser and Requisitions in the browser. Add/Edit: Ability to Edit Requisitions. Delete: Ability to delete Requisitions.'
      ]
    },
    'Service&Calibration': {
      ServiceBrowser: [
        'Access/View: ability to access and utilize the service browser menu functionalities'
      ],
      ServiceLog: [
        'Access/View: ability to view open or closed service requests',
        'Add/Edit: ability to create and edit existing service requests in the pending status',
        'Delete: ability to delete an open service request',
        'Perform: ability to convert a service request into a work order'
      ],
      ServiceEvent: [
        'Access/View: ability to view existing service events',
        'Add/Edit: ability to create and edit existing service events',
        'Delete: ability to delete service events',
        'Perform: ability to save a new service event and the ability to add event from model'
      ],
      ServiceTicket: [
        'Access/View: ability to view open or closed work orders',
        'Add/Edit: ability to create new and edit existing work orders',
        'Delete: ability to delete work orders',
        'Perform: ability to save and close a work order'
      ],
      ServiceSchedule: [
        'Access/View: ability to view open or scheduled events',
        'Add/Edit: ability to create new and edit existing Service Schedules',
        'Delete: ability to delete Service Schedules',
        'Perform: ability to save and close Service Schedules'
      ]
    },
    Purchasing: {
      PurchaseOrders: [
        'Access/View: ability to view open or closed purchase orders',
        'Add/Edit: ability to create new and edit existing purchase orders in draft status',
        'Delete: ability to delete open or closed purchase orders',
        'Perform: ability to use the receive purchase order button'
      ],
      POTicket: [
        'Access/View: ability to open purchase orders to view their details',
        'Add/Edit: ability to create new purchase orders in the draft status',
        'Delete: ability to delete purchase orders',
        'Perform: ability to save a purchase order'
      ],
      ProductVendors: [],
      PricingPlans: [],
      ReceivingTickets: [
        'Access/View: ability to view closed receiving tickets'
      ],
      ReceivingTicket: [
        'Access/View: ability to view an open or closed recieving ticket and the ability to open the purchase order, associated with the receiving ticket',
        'Add/Edit: ability to receive against a purchase order, create a new receiving ticket, and edit an open receiving ticket',
        'Perform: ability to recieve items into your inventory'
      ]
    },
    'JobCost&Billing': {
      'Billing&InvoiceBrowser': [
        'Access/View: ability to access and utilize the job cost & billing menu functionalities'
      ],
      BillingBrowser: [
        'Access/View: ability to open and see the billing browser page'
      ],
      'CalculateJobCost&Billing': [
        'Perform: ability to use the calculate button to calculate un-posted charges'
      ],
      'MiscCharge/Credit': [
        'Access/View: ability to see existing un-posted miscellaneous charges or credits',
        'Add/Edit: ability to create new and edit existing un-posted miscellaneous charges or credits',
        'Delete: ability to delete existing un-posted miscellaneous charges or credits'
      ],
      'PostJobCost&Billing': [
        'Perform: ability to post existing un-posted charges'
      ],
      RateSheets: [
        'Access/View: ability to see existing materials & consumables and tools & equipment rate sheets',
        'Add/Edit: ability to create new and edit existing materials & consumables and tools & equipment rate sheets',
        'Delete: ability to delete existing materials & consumables and tools & equipment rate sheets'
      ],
      CompanyCalendar: [
        'Access/View: ability to open and see the company calendar page',
        'Add/Edit: ability to edit the company calendar'
      ],
      'Calendar-CostCenter': [
        'Access/View: ability to open and see the company calendar page',
        'Add/Edit: ability to edit the company calendar'
      ],
      HourlyUsageLog: [
        'Access/View: ability to open and see equipment usage log',
        'Add/Edit: ability to add tools & equipment to the equipment usage log and edit lines in the equipment usage log',
        'Delete: ability to delete tools & equipment from the equipment usage log'
      ],
      'ExportJobCost&Billing': [
        'Perform: ability to use the export data button in the job cost & billing module'
      ]
    },
    Scheduling: {
      SchedulingRate: [
        'Access/View: the rate for a model when requesting scheduling'
      ],
      SchedulingAvailability: [
        'Access/View: the availability for a model when requesting scheduling'
      ],
      SchedulingBrowser: [
        'Access/View: the availability for a model when requesting scheduling'
      ],
      SchedulingCalendar: [
        'Access/View: the availability for a model when requesting scheduling'
      ],
      SchedulingRequest: [
        'Access/View: the availability for a model when requesting scheduling'
      ]
    },
    'Reports&Graphs': {
      Reports: [
        'Access/View: access to generate / download various reports and apply filters to capture unique tool data'
      ],
      ToolReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Tool reports'
      ],
      ListReports: [
        'Access/View: ability to run, print, PDF, and/or export data for List reports'
      ],
      AssignmentReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Transfer reports'
      ],
      BarCodeLabels: [
        'Access/View: ability to run, print, PDF, and/or export data for the Bar Code Label reports'
      ],
      ConsumableReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Consumable reports'
      ],
      PurchasingReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Purchasing reports'
      ],
      ServiceReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Service reports'
      ],
      BillingReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Billing reports'
      ],
      TasksReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Tasks reports'
      ],
      FuelReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Fuel reports'
      ],
      GraphReports: [
        'Access/View: ability to run, print, PDF, and/or export data for Graph reports'
      ],
      CustomReports: [
        'Access/View: ability to view and generate custom list reports',
        'Delete: ability to delete existing custom list reports'
      ],
      ScheduledReports: [
        'Access/View: ability to view the scheduled reports page menu',
        'Add/Edit: ability to add new and edit existing scheduled reports for assigned users with a determined email frequency for specific reports',
        'Delete: ability to delete existing scheduled reports'
      ],
      CustomDashboards: []
    },
    Tracking: {
      LocationMap: [
        'Access/View: ability to view the Location Map'
      ],
      FuelCards: [
        'Access/View: ability to access Fuel Cards from the navigation menu'
      ],
      WirelessGateways: [
        'Access/View: ability to access and view wireless gateways',
        'Add/Edit: ability to add new or edit existing wireless gateways',
        'Delete: ability to delete wireless gateways'
      ],
      WirelessTags: [
        'Access/View: ability to access and view wireless tags',
        'Add/Edit: ability to add new or edit existing wireless tags',
        'Delete: ability to delete wireless tags'
      ]
    },
    ToolWatchSettings: {
      Categories: [
        'Access/View: access to view existing tool, material, or template categories in settings list page',
        'Add/Edit: ability to create and manage tool, material or template categories',
        'Delete: ability to delete tool, material or template categories'
      ],
      Classes: [
        'Access/View: access to view existing classes in settings list page',
        'Add/Edit: ability to create and manage classes',
        'Delete: ability to delete classes'
      ],
      StatusCodes: [
        'Access/View: access to view existing status codes in settings list page',
        'Add/Edit: ability to create and manage status codes',
        'Delete: ability to delete status codes'
      ],
      ShippingMethods: [
        'Access/View: access to view existing shipping methods in settings list page',
        'Add/Edit: ability to create and manage shipping methods',
        'Delete: ability to delete shipping methods'
      ],
      UnitsofMeasure: [
        'Access/View: access to view existing units of measure in settings list page',
        'Add/Edit: ability to create and manage units of measure',
        'Delete: ability to delete units of measure'
      ],
      Vendors: [
        'Access/View: access to view existing vendors in settings list page',
        'Add/Edit: ability to create and manage vendors',
        'Delete: ability to delete vendors'
      ],
      CostCenters: [
        'Access/View: access to view existing Cost Centers in settings list page',
        'Add/Edit: ability to create and manage Cost Centers',
        'Delete: ability to delete Cost Centers'
      ],
      CostCodes: [
        'Access/View: access to view existing cost codes in settings list page',
        'Add/Edit: ability to create and manage cost codes',
        'Delete: ability to delete cost codes'
      ],
      DataMergeUtility: [
        'Perform: ability to merge data that is available in the Data Merger window'
      ]
    }
  }
}

export default platformPermissionsTooltip
