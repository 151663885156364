<template>
  <v-select
    v-model="selectedOrg"
    label="Description"
    class="req-org-v-select"
    :options="orgLists"
    :clearable="false"
    @input="$emit('onOrgSelected', $event), $emit('resetData', true)"
    placeholder=" "
  >
    <template #option="{ level, Description }">
      <span :class="{'font-weight-bolder': level === 1}"><i v-if="level === 2" class="fa-solid fa-building"></i>{{ Description }}</span>
    </template>
    <template #selected-option="{ level, Description, DescriptionTruncated }">
      <span
        v-tooltip="{
          content: Description,
          placement: 'top',
          trigger: DescriptionTruncated ? 'hover' : 'manual'
        }"
        :class="{'font-weight-bolder': level === 1}"
        :style="`width: ${dropdownWidth}px; text-wrap: nowrap; overflow: hidden;`"
      >
        <i v-if="level === 2" class="fa-solid fa-building" style="padding-right: 2px;"></i>
        {{ DescriptionTruncated ? DescriptionTruncated.trim() : Description.trim() }}
      </span>
    </template>
  </v-select>
</template>

<script>
import $ from 'jquery'
import vSelect from 'vue-select'
import { setProcoreShipTo } from '../../helpers/procore.js'
export default {
  name: 'ReqOrgList',
  components: {
    vSelect
  },
  data () {
    return {
      orgLists: [],
      selectedOrg: null,
      truncateDesc: 23,
      dropdownWidth: 180
    }
  },
  async created () {
    let orgLists = []

    if (this.platformNavigation) {
      if (!this.orgLocations.length) {
        await this.$store.dispatch('loadOrgLocations')
      }
      orgLists = [].concat(this.orgLocations)
        .map(({ twEntityId: Id, description: Description, parentId: ParentId, ...rest }) => ({
          Id, Description, ParentId, ...rest
        }))
    } else {
      if (!this.locations.length) {
        await this.$store.dispatch('loadStockLocations')
      }
      orgLists = [].concat(this.locations)
    }

    function createLevelArray (data) {
      const idToNode = {}
      const rootNodes = []

      data.forEach(item => {
        idToNode[item.Id] = item
      })

      data.forEach(item => {
        const parent = idToNode[item.ParentId]
        if (parent) {
          if (!parent.children) {
            parent.children = []
          }
          parent.children.push(item)
        } else {
          rootNodes.push(item)
        }
      })
      return rootNodes
    }

    const indentedOrgList = []
    const truncateDesc = this.truncateDesc

    function addIndentation (array, level) {
      level += 1
      array.forEach(item => {
        let indent
        const itemName = item.Description
        if (level === 1) {
          indent = ''
        } else if (level === 2) {
          indent = '\xA0'.repeat(2)
        } else if (level === 3) {
          indent = '\xA0'.repeat(5)
        } else if (level === 4) {
          indent = '\xA0\xA0'.repeat(level) + '- '
        } else {
          indent = '\xA0\xA0'.repeat(level + 1) + '- '
        }
        item.Description = indent + item.Description
        indentedOrgList.push({
          Id: item.Id,
          Description: item.Description,
          DescriptionTruncated: itemName.length > truncateDesc ? item.Description.trim().slice(0, level === 2 ? truncateDesc - 3 : truncateDesc) + ' ...' : null,
          IsStockpoint: item.IsStockpoint,
          StockpointId: item.StockpointId,
          ParentId: item.ParentId,
          level: level
        })
        if (item.children && item.children.length !== 0) {
          addIndentation(item.children, level)
        }
      })
    }

    const nestedArray = createLevelArray(orgLists, null)
    addIndentation(nestedArray, 0)

    const locationId = this.$store.getters.getStockpointId
    this.orgLists = indentedOrgList
    this.selectedOrg = this.orgLists.find(
      (list) => list.Id === locationId
    )
  },
  mounted: function () {
    if (process.env.VUE_APP_BRAND === 'ALIGN') {
      document.documentElement.style.setProperty('--navbar-dropdown-bg-color', '#20333c')
      document.documentElement.style.setProperty('--navbar-dropdown-text-color', '#fff')
      document.documentElement.style.setProperty('--navbar-dropdown-arrow-color', '#fff')
      this.truncateDesc = 25
      this.dropdownWidth = 195
    } else {
      document.documentElement.style.setProperty('--navbar-dropdown-bg-color', '#fff')
      document.documentElement.style.setProperty('--navbar-dropdown-text-color', '#000')
      document.documentElement.style.setProperty('--navbar-dropdown-arrow-color', '#9d9d9d')
    }
  },
  computed: {
    locations () {
      return this.$store.getters.getLocations
    },
    orgLocations () {
      return this.$store.getters.getOrgLocations
    },
    cart () {
      const cart = this.$store.getters.cart
      setProcoreShipTo(cart)
      return cart
    },
    platformNavigation () {
      return this.$store.getters.showPlatformNavigation
    }
  },
  directives: {
    tooltip: {
      inserted (el, binding) {
        $(el).tooltip({
          title: binding.value.content,
          placement: binding.value.placement,
          trigger: binding.value.trigger,
          delay: 200
        })
      }
    }
  }
}
</script>
<style scoped>
  .vs__selected-options > span.vs__selected {
    color: #fff !important;
  }
</style>
