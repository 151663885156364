const jobStocks = {
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'masterId',
        type: 'check'
      },
      {
        label: 'Category',
        key: 'category',
        type: String,
        sortable: true
      },
      {
        label: 'Model',
        key: 'model',
        type: String,
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        type: String,
        sortable: true
      },
      {
        label: 'Qty',
        key: 'inStock',
        type: Number,
        sortable: true
      },
      {
        label: 'On Requisition Qty',
        key: 'requisitionQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Low Qty',
        key: 'lowLevel',
        type: Number,
        sortable: true
      },
      {
        label: 'Target Qty',
        key: 'targetLevel',
        type: Number,
        sortable: true
      },
      {
        label: 'High Qty',
        key: 'highLevel',
        type: Number,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'masterId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'category',
            type: String,
            sortable: true
          },
          {
            label: 'Model',
            key: 'model',
            type: String,
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            type: String,
            sortable: true
          },
          {
            label: 'Qty',
            key: 'inStock',
            type: Number,
            sortable: true
          },
          {
            label: 'On Requisition Qty',
            key: 'requisitionQty',
            type: Number,
            sortable: true
          },
          {
            label: 'Low Qty',
            key: 'lowLevel',
            type: Number,
            sortable: true
          },
          {
            label: 'Target Qty',
            key: 'targetLevel',
            type: Number,
            sortable: true
          },
          {
            label: 'High Qty',
            key: 'highLevel',
            type: Number,
            sortable: true
          }
        ]
      }
    ]
  },
  tableHeaderWithPurchaseQty: {
    desktop: [
      {
        label: '',
        key: 'masterId',
        type: 'check'
      },
      {
        label: 'Category',
        key: 'category',
        type: String,
        sortable: true
      },
      {
        label: 'Model',
        key: 'model',
        type: String,
        sortable: true
      },
      {
        label: 'Description',
        key: 'description',
        type: String,
        sortable: true
      },
      {
        label: 'Qty',
        key: 'inStock',
        type: Number,
        sortable: true
      },
      {
        label: 'On Requisition Qty',
        key: 'requisitionQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Purchase Qty',
        key: 'requisitionPurchaseOrderQty',
        type: Number,
        sortable: true
      },
      {
        label: 'Low Qty',
        key: 'lowLevel',
        type: Number,
        sortable: true
      },
      {
        label: 'Target Qty',
        key: 'targetLevel',
        type: Number,
        sortable: true
      },
      {
        label: 'High Qty',
        key: 'highLevel',
        type: Number,
        sortable: true
      }
    ],
    mobile: [
      {
        label: 'check',
        key: 'masterId',
        type: 'check'
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Category',
            key: 'category',
            type: String,
            sortable: true
          },
          {
            label: 'Model',
            key: 'model',
            type: String,
            sortable: true
          },
          {
            label: 'Description',
            key: 'description',
            type: String,
            sortable: true
          },
          {
            label: 'Qty',
            key: 'inStock',
            type: Number,
            sortable: true
          },
          {
            label: 'On Requisition Qty',
            key: 'requisitionQty',
            type: Number,
            sortable: true
          },
          {
            label: 'Purchase Qty',
            key: 'requisitionPurchaseOrderQty',
            type: Number,
            sortable: true
          },
          {
            label: 'Low Qty',
            key: 'lowLevel',
            type: Number,
            sortable: true
          },
          {
            label: 'Target Qty',
            key: 'targetLevel',
            type: Number,
            sortable: true
          },
          {
            label: 'High Qty',
            key: 'highLevel',
            type: Number,
            sortable: true
          }
        ]
      }
    ]
  },
  lineActions: [
    {
      title: 'View Detail',
      actionName: 'viewJobStock',
      permissions: ['JobStockView']
    },
    {
      title: 'Add to Requisition',
      actionName: 'addToReq'
    },
    {
      title: 'Add Model',
      actionName: 'addModel',
      permissions: ['JobStockEdit']
    },
    {
      title: 'Delete Model',
      actionName: 'delete',
      permissions: ['JobStockDelete']
    }
  ],
  buttons: {
    exportbtn: 'Export',
    levelMangerbtn: 'Job Level Manager',
    savebtn: 'Save',
    backbtn: 'Back',
    cancelbtn: 'Cancel',
    yesBtn: 'Yes',
    noBtn: 'No'
  },
  jobStockModalTitlesAndMsg: {
    requisitionModalTitles: 'Requisition Cart',
    successToastMsg: 'Deliver To Location Changed.',
    requisitionModalMsg: 'There are already items in the Requisition cart. Do you want to add these models to the cart in progress? "No" will clear the in progress cart and start a new cart.'
  },
  jobLevelManager: {
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'masterId',
          type: 'check'
        },
        {
          label: 'Category',
          key: 'category',
          type: String,
          sortable: true
        },
        {
          label: 'Model',
          key: 'model',
          type: String,
          sortable: true
        },
        {
          label: 'Description',
          key: 'description',
          type: String,
          sortable: true
        },
        {
          label: 'Low Qty',
          key: 'lowLevel',
          type: Number,
          sortable: true,
          editable: true
        },
        {
          label: 'Target Qty',
          key: 'targetLevel',
          type: Number,
          sortable: true,
          editable: true
        },
        {
          label: 'High Qty',
          key: 'highLevel',
          type: Number,
          sortable: true,
          editable: true
        }
      ],
      mobile: [
        {
          label: 'check',
          key: 'masterId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Category',
              key: 'category',
              type: String,
              sortable: true
            },
            {
              label: 'Model',
              key: 'model',
              type: String,
              sortable: true
            },
            {
              label: 'Description',
              key: 'description',
              type: String,
              sortable: true
            },
            {
              label: 'Low Qty',
              key: 'lowLevel',
              type: Number,
              sortable: true,
              editable: true
            },
            {
              label: 'Target Qty',
              key: 'targetLevel',
              type: Number,
              sortable: true,
              editable: true
            },
            {
              label: 'High Qty',
              key: 'highLevel',
              type: Number,
              sortable: true,
              editable: true
            }
          ]
        }
      ]
    },
    lineActions: [
      {
        title: 'Add Model',
        actionName: 'addJobManagerModel'
      },
      {
        title: 'Delete Model',
        actionName: 'deleteJobManagerModel'
      }
    ]
  },
  validation: {
    lowLevel: 'Low must be lower than target and high',
    targetLevel: 'Target must be higher than low and lower than high',
    highLevel: 'High must be higher than low and target',
    errorMsg: 'Please correct quantity on highlighted fields'
  }
}
export default jobStocks
