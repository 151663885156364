const requisition = {
  homeTitle: 'Requisitions',
  history: 'Request History',
  greeting: 'Hello',
  placeholder: {
    0: 'What are you looking for?',
    1: 'What are you returning?'
  },
  openCart: {
    title: 'Open',
    noMsg: 'No Open Requests',
    requestNo: 'Request No.',
    quantity: 'Quantity'
  },
  delivered: {
    title: 'Delivered',
    noMsg: 'No Delivered Requests'
  },
  reRequest: {
    title: 'Request Again',
    noMsg: 'No Requests'
  },
  noMsg: 'There are no results that match your search description.',
  stock: {
    inStock: '{count} in stock',
    outofStock: 'Out of stock'
  },
  productDetail: {
    title: 'Product Detail'
  },
  assignmentChanged: {
    title: 'Assigned Stock Location Changed',
    message: 'Your assigned stock location has changed.',
    confirmSettings: 'Please confirm your settings.',
    invalidJob: 'Your previously selected job is no longer valid. Please choose another one.'
  },
  productInfo: {
    title: 'Product Information',
    labels: {
      0: 'Manufacturer',
      1: 'Model',
      2: 'Catalog #',
      3: 'UPC/ASIN',
      4: 'Year',
      5: 'Model Id'
    }
  },
  productTracking: {
    title: 'Tracking Information',
    labels: {
      0: 'Category',
      1: 'Generic Description',
      2: 'Item Type',
      3: 'Tracking Method',
      4: 'Default Class',
      5: 'Certification',
      6: 'Meter Required'
    }
  },
  cart: {
    title: 'Cart',
    desc: 'Items in your cart',
    noMsg: 'No Cart',
    actionHeader: {
      0: {
        title: 'Collaborate',
        icon: 'fa-regular fa-comment-dots',
        key: 'collaborate',
        isShow: true
      }
    },
    requiredRequestFrom: 'Request From is required',
    requiredDeliverTo: 'Deliver To is required'
  },
  checkout: {
    title: 'Checkout',
    desc: 'Review your checkout',
    labels: {
      0: 'Submit to',
      1: 'Location & Cost Center',
      2: 'Date Needed',
      3: 'Comments',
      4: 'Review Items',
      5: 'Return To',
      6: 'Pick Up'
    },
    actionHeader: {
      0: {
        title: 'Collaborate',
        icon: 'fa-regular fa-comment-dots',
        key: 'collaborate',
        isShow: true
      }
    }
  },
  editModal: {
    title: 'Welcome',
    logout: 'Log out',
    for: 'For',
    labels: {
      0: 'Portal',
      1: 'Organization Tree',
      2: 'Locations',
      3: 'Cost Center',
      4: 'Cost Code',
      5: 'Address',
      6: 'Deliver To',
      7: 'Request From',
      8: 'Return From'
    }
  },
  reqHistory: {
    title: 'Request History',
    placeholder: 'Search all requests',
    labels: {
      0: 'By item',
      1: 'By Requisition',
      2: 'Request Again'
    },
    filter: {
      status: {
        0: 'All',
        1: 'Open',
        2: 'Closed',
        3: 'Draft',
        4: 'Submitted',
        5: 'Completed'
      },
      time: {
        0: 'Last 30 days',
        1: 'Past 3 months'
      }
    },
    itemDetail: {
      0: 'Status',
      1: 'Qty',
      2: 'Date',
      3: 'Document',
      4: 'Source'
    }
  },
  confirmDlg: {
    title: 'Change Location',
    message: 'Your Cart already contains ({count}) items. You must remove your items if you want to change your location.'
  },
  return: {
    title: 'Returns',
    location: 'Returning from',
    labels: {
      0: 'Start a Return',
      1: 'Returns in Progress',
      2: 'Recently Returned'
    },
    searchPage: {
      header: 'Select items to return',
      switcherLabel: 'Hide items scheduled for return',
      itemSelected: 'No item selected | 1 item selected | {count} items selected',
      searchOutsideJob: 'Search for items not assigned to this job',
      searchingOutsideJob: 'Searching outside job'
    },
    ticket: {
      title: 'Return Ticket',
      placeholder: 'Search this ticket',
      items: 'No item | 1 item | {count} items',
      actionLabels: {
        0: 'Actions',
        1: 'Copy {product}',
        2: 'Merge {product}',
        3: 'Remove {product}'
      },
      number: 'Return No.',
      noMsg: 'No Ticket',
      onSite: '{count} on site',
      returning: '{count} on returning'
    },
    summary: {
      title: 'Return Summary'
    },
    history: {
      title: 'Return History',
      placeholder: 'Search all returns',
      labels: {
        0: 'Status',
        1: 'Qty',
        2: 'Date',
        3: 'Document',
        4: 'By'
      }
    },
    confirmDlg: {
      title: 'Change Return Ticket',
      message: 'This Return ticket already contains ({count}) items from the {location}. You can\'t add items from another location. <br> You can complete the current ticket, or cancel the ticket and start a new ticket.</br>'
    },
    noItem: 'No {title} Items',
    deleteReturn: {
      title: 'Delete Return Ticket',
      message: 'You are about to delete the entire Return Ticket ({count}) items?',
      yes: 'Yes, delete ticket',
      no: 'No, cancel'
    }
  },
  reqBrowser: {
    title: 'Requisition Browser',
    labels: {
      open: 'Open',
      closed: 'Closed',
      all: 'All'
    },
    browserLineActions: [
      {
        title: 'View',
        actionName: 'onView',
        requiresSelection: true,
        allowsMultiSelect: false
      },
      {
        title: 'Delete',
        actionName: 'onDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    browserLineActionsView: [
      {
        title: 'View',
        actionName: 'onView',
        requiresSelection: true,
        allowsMultiSelect: false
      }
    ],
    browserLineActionsDelete: [
      {
        title: 'Delete',
        actionName: 'onDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  },
  reqBrowserView: {
    title: 'Requisition View',
    titlePurchaseOrder: 'Purchase Order',
    titlePickTicket: 'Pick Ticket',
    titleReject: 'Reject',
    titleUndoReject: 'Undo Reject',
    subActionHeader: {
      0: {
        title: 'Save',
        icon: 'fas fa-save',
        isShow: true
      },
      1: {
        title: 'Print',
        key: 'Print',
        icon: 'fas fa-print',
        isShow: true
      },
      2: {
        title: 'Add Template',
        icon: 'fas fa-plus',
        isShow: true
      },
      3: {
        title: 'Add Record',
        icon: 'fas fa-plus',
        isShow: true
      },
      4: {
        title: 'Attachments',
        icon: 'fas fa-paperclip',
        isShow: true
      },
      5: {
        title: 'Back',
        icon: 'fas fa-undo',
        isShow: true
      },
      6: {
        title: 'Collaborate',
        key: 'collaborate',
        icon: 'fa-regular fa-comment-dots',
        isShow: true,
        isDraft: true
      }
    },
    collaborationHeader: {
      5: {
        title: 'Back',
        icon: 'fas fa-undo',
        isShow: true
      },
      6: {
        title: 'Collaborate',
        key: 'collaborate',
        icon: 'fa-regular fa-comment-dots',
        isShow: true,
        isDraft: true
      }
    },
    cart: {
      desc: 'Items in the transfer ticket',
      labels: {
        0: 'Requisition No.',
        1: 'Status'
      }
    },
    noRequisitionSelected: {
      title: 'No requisition item selected.'
    },
    ticketHolder: {
      labels: {
        0: 'From',
        1: 'To',
        2: 'Requisition No.',
        3: 'Requested By',
        4: 'Due Date',
        5: 'Assigned To',
        6: 'Date Closed',
        7: 'Pick Ticket Summary',
        8: 'Transfer picked items?',
        9: 'Pick List',
        10: 'Picked Items',
        11: 'Question',
        12: 'Close this Ticket?',
        13: 'Ready to pick?',
        14: 'Return Date'
      }
    },
    browserLineActions: [
      {
        title: 'Purchase Order',
        actionName: 'onPurchasOrder',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Pick Ticket',
        actionName: 'onPickTicket',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Reject',
        actionName: 'onReject',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Undo Reject',
        actionName: 'onUndoReject',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'Delete',
        actionName: 'onDelete',
        requiresSelection: true,
        allowsMultiSelect: true
      },
      {
        title: 'History',
        actionName: 'onHistory',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ],
    browserLineHistory: [
      {
        title: 'History',
        actionName: 'onHistory',
        requiresSelection: true,
        allowsMultiSelect: true
      }
    ]
  },
  reqBrowserCreate: {
    title: 'Requisition',
    subtitle: 'Requisition',
    addBackLink: {
      0: {
        title: 'Back to Requisition',
        icon: 'fas fa-undo'
      }
    },
    requisitonRequestCart: {
      labels: {
        0: 'Requested From',
        1: 'Requested To',
        2: 'Back to Requisition'
      }
    },
    ticketItem: {
      title: 'Items in Requisition',
      labels: {
        0: 'IsGeneric'
      }
    }
  }
}

export default requisition
