const itemBrowser = {
  tool: {
    title: 'Item Browser',
    equipmentTitle: 'Equipment Browser',
    modelBrowser: 'Tool Model Browser',
    actionHeader: {
      0: {
        title: 'Add Item',
        icon: 'fas fa-plus',
        permissions: ['ToolDetailEdit', 'MaterialDetailEdit']
      },
      3: {
        title: 'Print',
        icon: 'fas fa-print'
      },
      4: {
        title: 'Export',
        icon: 'fas fa-file-export'
      }
    },
    csvHeader: [
      {
        key: 'category',
        label: 'category'
      },
      {
        key: 'model',
        label: 'model'
      },
      {
        key: 'itemType',
        label: 'itemType'
      },
      {
        key: 'barcode',
        label: 'barcode'
      },
      {
        key: 'itemNumber',
        label: 'itemNumber'
      },
      {
        key: 'assignedTo',
        label: 'assignedTo'
      },
      {
        key: 'owner',
        label: 'owner'
      },
      {
        key: 'kitNumber',
        label: 'kitNumber'
      },
      {
        key: 'description',
        label: 'description'
      },
      {
        key: 'manufacturer',
        label: 'manufacturer'
      },
      {
        key: 'personalIssue',
        label: 'personalIssue'
      },
      {
        key: 'itemStatus',
        label: 'itemStatus'
      },
      {
        key: 'manager',
        label: 'manager'
      },
      {
        key: 'quantity',
        label: 'quantity'
      },
      {
        key: 'supervisor',
        label: 'supervisor'
      },
      {
        key: 'department',
        label: 'department'
      },
      {
        key: 'uom',
        label: 'uom'
      },
      {
        key: 'serialNumber',
        label: 'serialNumber'
      },
      {
        key: 'className',
        label: 'className'
      },
      {
        key: 'ageDays',
        label: 'ageDays'
      },
      {
        key: 'icon',
        label: 'icon'
      },
      {
        key: 'replacementCost',
        label: 'replacementCost'
      },
      {
        key: 'notes',
        label: 'notes'
      }
    ],
    csvExpandedHeader: [
      {
        key: 'category',
        label: 'category'
      },
      {
        key: 'model',
        label: 'model'
      },
      {
        key: 'itemType',
        label: 'itemType'
      },
      {
        key: 'barcode',
        label: 'barcode'
      },
      {
        key: 'itemNumber',
        label: 'itemNumber'
      },
      {
        key: 'returnDate',
        label: 'returnDate'
      },
      {
        key: 'assignedTo',
        label: 'assignedTo'
      },
      {
        key: 'owner',
        label: 'owner'
      },
      {
        key: 'kitNumber',
        label: 'kitNumber'
      },
      {
        key: 'description',
        label: 'description'
      },
      {
        key: 'manufacturer',
        label: 'manufacturer'
      },
      {
        key: 'personalIssue',
        label: 'personalIssue'
      },
      {
        key: 'itemStatus',
        label: 'itemStatus'
      },
      {
        key: 'manager',
        label: 'manager'
      },
      {
        key: 'quantity',
        label: 'quantity'
      },
      {
        key: 'supervisor',
        label: 'supervisor'
      },
      {
        key: 'lastTransferDeliveredBy',
        label: 'lastTransferDeliveredBy'
      },
      {
        key: 'lastTransferDate',
        label: 'lastTransferDate'
      },
      {
        key: 'lastTransferNumber',
        label: 'lastTransferNumber'
      },
      {
        key: 'department',
        label: 'department'
      },
      {
        key: 'uom',
        label: 'uom'
      },
      {
        key: 'serialNumber',
        label: 'serialNumber'
      },
      {
        key: 'binId',
        label: 'binId'
      },
      {
        key: 'lastCountDate',
        label: 'lastCountDate'
      },
      {
        key: 'className',
        label: 'className'
      },
      {
        key: 'responsibleParty',
        label: 'responsibleParty'
      },
      {
        key: 'lastScannedOn',
        label: 'lastScannedOn'
      },
      {
        key: 'ageDays',
        label: 'ageDays'
      },
      {
        key: 'lastScanArea',
        label: 'lastScanArea'
      },
      {
        key: 'lastScanLocation',
        label: 'lastScanLocation'
      },
      {
        key: 'icon',
        label: 'icon'
      },
      {
        key: 'ble',
        label: 'ble'
      },
      {
        key: 'replacementCost',
        label: 'replacementCost'
      },
      {
        key: 'notes',
        label: 'notes'
      },
      {
        key: 'InKitType',
        label: 'kit'
      },
      {
        key: 'vin',
        label: 'vin'
      },
      {
        key: 'licensePlateState',
        label: 'licenseplatestate'
      },
      {
        key: 'licensePlateNumber',
        label: 'licenseplatenumber'
      },
      {
        key: 'needsVerificationScore',
        label: 'needsverify'
      },
      {
        key: 'meterLevel',
        label: 'meter'
      },
      {
        key: 'modelYear',
        label: 'modelyear'
      },
      {
        key: 'hasBleTag',
        label: 'wireless'
      },
      {
        key: 'available',
        label: 'available'
      },
      {
        key: 'pickedQuantity',
        label: 'allocated'
      },
      {
        key: 'createdOn',
        label: 'createdon'
      },
      {
        key: 'createdBy',
        label: 'createdby'
      }
    ],
    type: [
      {
        key: '\xa0',
        value: 0
      },
      {
        key: 'Tool',
        value: 1
      },
      {
        key: 'Bulk Item',
        value: 2
      },
      {
        key: 'Equipment',
        value: 1
      },
      {
        key: 'Kit',
        value: 4
      }
    ],
    lineActions: [
      {
        title: 'Add Item',
        actionName: 'addItem'
      },
      {
        title: 'Print',
        actionName: 'printItem'
      },
      {
        title: 'Export',
        actionName: 'exportItem'
      },
      {
        title: '------------------------',
        actionName: null
      },
      {
        title: 'Add Attachment',
        actionName: 'addAttachment',
        isShown: function (items) {
          if (items.some(item => item.itemType == 'K')) {
            return false
          }
          return true
        },
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: false,
        isMaterial: true,
        isWarehouse: true
      },
      {
        title: 'Add To Transfer',
        actionName: 'addToTransfer',
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        isMaterial: true,
        isWarehouse: true,
        permissions: ['TransfersExecute']
      },
      {
        title: 'View Detail & Edit Item',
        actionName: 'viewDetail',
        isMultiple: false,
        isTool: true,
        isQuantity: true,
        isKit: true,
        isMaterial: true
      },
      {
        title: 'View Meter History',
        actionName: 'viewMeterHistory',
        isMultiple: false,
        isTool: true,
        isQuantity: false,
        isKit: false,
        permissions: ['ToolDetailView', 'MaterialDetailView']
      },
      {
        title: 'View Hours History',
        actionName: 'viewHoursHistory',
        isMultiple: false,
        isTool: true,
        isQuantity: false,
        isKit: false,
        permissions: ['ToolDetailView', 'MaterialDetailView']
      },
      {
        title: 'View Transaction History',
        actionName: 'viewTransactionHistory',
        isMultiple: false,
        isTool: true,
        isQuantity: true,
        isKit: true,
        isMaterial: true,
        permissions: ['ToolDetailView', 'MaterialDetailView']
      },
      {
        title: 'View Aging History',
        actionName: 'viewAgingHistory',
        isMultiple: false,
        isTool: true,
        isQuantity: false,
        isKit: false,
        permissions: ['ToolDetailView', 'MaterialDetailView']
      },
      {
        title: 'View Scans',
        actionName: 'viewScans',
        isMultiple: false,
        isTool: true,
        isQuantity: false,
        isKit: false,
        isShown: function (items) {
          if (!items.length) return false
          return 'lastScanLocation' in items[0]
        },
        permissions: ['ToolDetailView', 'MaterialDetailView']
      },
      {
        title: 'Retire Tool',
        actionName: 'retiredItem',
        isShown: function (items) {
          if (items.length == 1 && items[0].itemType == 'K') {
            return false
          } else if (items.some(item => item.itemType == 'M')) {
            return false
          }
          return true
        },
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        permissions: ['RetireToolExecute']
      },
      {
        title: 'Delete Kit',
        actionName: 'deleteKit',
        isShown: function (items) {
          return items.length == 1 && items[0].itemType == 'K'
        },
        isKit: true,
        permissions: ['KitsDelete']
      },
      {
        title: 'Set Responsible Party',
        actionName: 'setResponsibleParty',
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        permissions: ['ToolDetailEdit', 'MaterialDetailEdit']
      },
      {
        title: 'Change Owner',
        actionName: 'changeOwner',
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        permissions: ['ToolDetailEdit', 'MaterialDetailEdit']
      },
      {
        title: 'Change Status',
        actionName: 'changeStatus',
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        permissions: ['ToolDetailEdit', 'MaterialDetailEdit']
      },
      {
        title: 'Change Department',
        actionName: 'changeDepartment',
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        permissions: ['ToolDetailEdit', 'MaterialDetailEdit']
      },
      {
        title: 'Change Item Class',
        actionName: 'changeItemClass',
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        permissions: ['ToolDetailEdit', 'MaterialDetailEdit']
      },
      {
        title: 'Change Return Date',
        actionName: 'changeReturnDate',
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        permissions: ['ToolDetailEdit', 'MaterialDetailEdit']
      },
      {
        title: 'Adjust Inventory',
        actionName: 'adjustInventory',
        isMultiple: false,
        isTool: false,
        isKit: false,
        isQuantity: true,
        isMaterial: true,
        permissions: ['AdjustInventoryExecute']
      },
      {
        title: 'View Adjustment History',
        actionName: 'viewAdjustmentHistory',
        isMultiple: false,
        isTool: false,
        isKit: false,
        isQuantity: true,
        isMaterial: true,
        permissions: ['ToolDetailView', 'MaterialDetailView']
      },
      {
        title: 'Remove Inventory Record',
        actionName: 'removeInventoryRecord',
        isMultiple: true,
        isTool: false,
        isKit: false,
        isQuantity: true,
        isMaterial: true,
        permissions: ['AdjustInventoryExecute']
      },
      {
        title: 'Add To Kit',
        actionName: 'addToKit',
        isMultiple: true,
        isTool: true,
        isKit: false,
        isQuantity: true,
        isMaterial: true,
        permissions: ['KitsEdit']
      },
      {
        title: 'Change Model',
        actionName: 'changeModel',
        isMultiple: true,
        isTool: true,
        isKit: true,
        isQuantity: false,
        permissions: ['ToolDetailEdit', 'MaterialDetailEdit']
      },
      {
        title: 'Edit Kit',
        actionName: 'editKit',
        isMultiple: false,
        isTool: false,
        isQuantity: false,
        isKit: true,
        permissions: ['KitsEdit']
      },
      {
        title: 'Add To Pick Ticket',
        actionName: 'addToPickTicket',
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        isMaterial: true,
        isWarehouse: true,
        permissions: ['PickTicketEdit']
      },
      {
        title: 'Add To Purchase Order',
        actionName: 'addToPurchaseOrder',
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        isMaterial: true,
        isWarehouse: true,
        permissions: ['PurchaseOrdersEdit']
      },
      {
        title: 'Create Service Request',
        actionName: 'createServiceRequest',
        isMultiple: false,
        isTool: true,
        isQuantity: false,
        isKit: false,
        isWarehouse: true,
        permissions: ['ServiceEventEdit']
      },
      {
        title: 'Create Work Order',
        actionName: 'createWorkOrder',
        isMultiple: false,
        isTool: true,
        isQuantity: false,
        isKit: false,
        isWarehouse: true,
        permissions: ['ServiceTicketEdit']
      },
      {
        title: 'Add To Count Ticket',
        actionName: 'addToCountTicket',
        isMultiple: true,
        isTool: true,
        isQuantity: true,
        isKit: true,
        isMaterial: true,
        isWarehouse: true,
        permissions: ['CountInventoryEdit']
      }
    ],
    viewMeterHistory: {
      title: 'Meter History',
      tableHeader: {
        desktop: [
          {
            label: 'Meter Level',
            key: 'MeterLevel',
            sortable: true
          },
          {
            label: 'Created On',
            key: 'CreatedOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Created By',
            key: 'CreatedBy',
            sortable: true
          },
          {
            label: 'Source',
            key: 'SourceId',
            type: 'source',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Meter Level',
            key: 'MeterLevel',
            sortable: true
          },
          {
            label: 'Info',
            data: [
              {
                label: 'Created On',
                key: 'CreatedOn',
                type: Date,
                sortable: true
              },
              {
                label: 'Created By',
                key: 'CreatedBy',
                sortable: true
              },
              {
                label: 'Source',
                key: 'SourceId',
                type: 'source',
                sortable: true
              }
            ]
          }
        ]
      }
    },
    viewHoursHistory: {
      title: 'Hours History',
      tableHeader: {
        desktop: [
          {
            label: 'Hours',
            key: 'EquipmentHours',
            sortable: true
          },
          {
            label: 'Created On',
            key: 'CreatedOn',
            type: Date,
            sortable: true
          },
          {
            label: 'Created By',
            key: 'CreatedBy',
            sortable: true
          },
          {
            label: 'Source',
            key: 'SourceId',
            type: 'source',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Hours',
            key: 'EquipmentHours',
            sortable: true
          },
          {
            label: 'Info',
            data: [
              {
                label: 'Created On',
                key: 'CreatedOn',
                type: Date,
                sortable: true
              },
              {
                label: 'Created By',
                key: 'CreatedBy',
                sortable: true
              },
              {
                label: 'Source',
                key: 'SourceId',
                type: 'source',
                sortable: true
              }
            ]
          }
        ]
      }
    },
    transferHistory: {
      title: 'Transfer History',
      tableHeader: {
        desktop: [
          {
            label: 'Tran. Date',
            type: 'DateAndTime',
            key: 'TransactionDate',
            sortable: true
          },
          {
            label: 'Tran. No.',
            key: 'TransferNumber',
            sortable: true
          },
          {
            label: 'Type',
            key: 'TransactionType',
            sortable: true
          },
          {
            label: 'Description',
            key: 'MasterDescription',
            sortable: true
          },
          {
            label: 'Qty',
            key: 'Quantity',
            sortable: true
          },
          {
            label: 'Assigned To',
            key: 'AssignedTo',
            sortable: true
          },
          {
            label: 'Assigned Date',
            type: 'DateAndTime',
            key: 'AssignmentDate',
            sortable: true
          },
          {
            label: 'Status',
            key: 'Status',
            sortable: true
          },
          {
            label: 'PI',
            type: Boolean,
            key: 'PersonalIssue',
            sortable: true
          },
          {
            label: 'Owner',
            key: 'Owner',
            sortable: true
          },
          {
            label: 'Kit',
            key: 'KitNumber',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Tran. Date',
            type: Date,
            key: 'TransactionDate',
            sortable: true
          },
          {
            label: 'Info',
            data: [
              {
                label: 'Tran. No.',
                key: 'TransferNumber',
                sortable: true
              },
              {
                label: 'Type',
                key: 'TransactionType',
                sortable: true
              },
              {
                label: 'Description',
                key: 'MasterDescription',
                sortable: true
              },
              {
                label: 'Qty',
                key: 'Quantity',
                sortable: true
              },
              {
                label: 'Assigned To',
                key: 'AssignedTo',
                sortable: true
              },
              {
                label: 'Assigned Date',
                type: Date,
                key: 'AssignmentDate',
                sortable: true
              },
              {
                label: 'Status',
                key: 'Status',
                sortable: true
              },
              {
                label: 'PI',
                type: Boolean,
                key: 'PersonalIssue',
                sortable: true
              },
              {
                label: 'Owner',
                key: 'Owner',
                sortable: true
              },
              {
                label: 'Kit',
                key: 'KitNumber',
                sortable: true
              }
            ]
          }
        ]
      }
    },
    agingHistory: {
      title: 'Aging History',
      scanTitle: 'Scans',
      tableHeader: {
        desktop: [
          {
            label: '',
            key: 'ConsecutiveScans',
            type: 'ScanCount',
            sortable: true
          },
          {
            label: 'Time Scanned',
            type: 'DateAndTime',
            sortable: true,
            key: 'ScannedOn'
          },
          {
            label: 'Scan Type',
            sortable: true,
            type: 'trackingSourceType',
            key: 'SourceType'
          },
          {
            label: 'Scanned By',
            sortable: true,
            key: 'ScannedByDescription'
          },
          {
            label: 'Scan Location',
            sortable: true,
            key: 'ScannedAtDescription'
          },
          {
            label: 'Scan Area',
            sortable: true,
            key: 'ScannedAtArea'
          },
          {
            label: 'Latitude',
            sortable: true,
            key: 'ScannedAtLatitude'
          },
          {
            label: 'Longitude',
            sortable: true,
            key: 'ScannedAtLongitude'
          }
        ],
        mobile: [
          {
            label: '',
            key: 'ConsecutiveScans',
            type: 'ScanCount',
            sortable: true
          },
          {
            label: 'Time Scanned',
            type: 'DateAndTime',
            sortable: true,
            key: 'ScannedOn'
          },
          {
            label: 'Info',
            data: [
              {
                label: 'Scan Type',
                sortable: true,
                type: 'trackingSourceType',
                key: 'SourceType'
              },
              {
                label: 'Scanned By',
                sortable: true,
                key: 'ScannedByDescription'
              },
              {
                label: 'Scan Location',
                sortable: true,
                key: 'ScannedAtDescription'
              },
              {
                label: 'Scan Area',
                sortable: true,
                key: 'ScannedAtArea'
              },
              {
                label: 'Latitude',
                sortable: true,
                key: 'ScannedAtLatitude'
              },
              {
                label: 'Longitude',
                sortable: true,
                key: 'ScannedAtLongitude'
              }
            ]
          }
        ]
      },
      toolTipInfo: 'This item was scanned {count} times at this location between {firstDate} and {lastDate}'
    },
    adjustmentHistory: {
      title: 'Adjustment History',
      tableHeader: {
        desktop: [
          {
            label: 'Adjustment Date',
            type: 'DateAndTime',
            key: 'AdjustmentDate',
            sortable: true
          },
          {
            label: 'Stock Location',
            key: 'StockLocation',
            sortable: true
          },
          {
            label: 'Beginning Qty',
            key: 'CurrentQuantity',
            sortable: true
          },
          {
            label: 'Adjusted Qty',
            key: 'NewQuantity',
            sortable: true
          },
          {
            label: 'Difference',
            key: 'DeltaQuantity',
            sortable: true
          },
          {
            label: 'Adjusted By',
            key: 'AdjustedBy',
            sortable: true
          },
          {
            label: 'Reason',
            key: 'Reason',
            sortable: true
          },
          {
            label: 'Document No.',
            key: 'DocumentNumber',
            sortable: true
          },
          {
            label: 'Full Count',
            type: Boolean,
            key: 'FullCount',
            sortable: true
          }
        ],
        mobile: [
          {
            label: 'Adjustment Date',
            type: 'DateAndTime',
            key: 'AdjustmentDate',
            sortable: true
          },
          {
            label: 'Info',
            data: [
              {
                label: 'Stock Location',
                key: 'StockLocation',
                sortable: true
              },
              {
                label: 'Beginning Qty',
                key: 'CurrentQuantity',
                sortable: true
              },
              {
                label: 'Adjusted Qty',
                key: 'NewQuantity',
                sortable: true
              },
              {
                label: 'Difference',
                key: 'DeltaQuantity',
                sortable: true
              },
              {
                label: 'Adjusted By',
                key: 'AdjustedBy',
                sortable: true
              },
              {
                label: 'Reason',
                key: 'Reason',
                sortable: true
              },
              {
                label: 'Document No.',
                key: 'DocumentNumber',
                sortable: true
              },
              {
                label: 'Full Count',
                type: Boolean,
                key: 'FullCount',
                sortable: true
              }
            ]
          }
        ]
      }
    },
    setResponsibleParty: {
      title: 'Set Reponsible Party',
      dlgHeaders: {
        0: 'Unable to set Responsible Party',
        1: 'Select a Responsible Part for the 1 selected item | Select a Responsible Part for the {count} selected items.'
      },
      dlgTexts: {
        0: 'You cannot set Responsible Party to items assigned to a stock location.',
        1: 'You cannot set Responsible Party to items tracked by quantity.',
        2: 'A blank selection will remove current Responsible Party.'
      }
    },
    sendToSiteManager: '1 item has been sent to Site Manager | {count} items have been sent to Site Manager',
    changeOwner: {
      title: 'Change Owner',
      text: 'You are about to change the owner for 1 tool. Are you sure you want to proceed? | You are about to change the owner for {count} tools. Are you sure you want to proceed?',
      label: 'Owner'
    },
    changeStatus: {
      title: 'Change Status',
      text: 'Select the new status for 1 tool. Do you wish to proceed? | Select the new status for {count} tools. Do you wish to proceed?',
      label: 'Status'
    },
    changeDepartment: {
      title: 'Change Department',
      text: 'You have selected 1 unique tool for a department change. Do you wish to proceed? | You have selected {count} unique tools for a department change. Do you wish to proceed?',
      label: 'New Department'
    },
    changeItemClass: {
      title: 'Change Item Class',
      text: 'You have selected 1 uniquely tracked tool for a class change. Do you wish to proceed? | You have selected {count} uniquely tracked tools for a class change. Do you wish to proceed?',
      label: 'New Item Class'
    },
    cannotChangeItemClass: {
      title: 'Change Item Class',
      message: 'Only uniquely tracked tools can have an item class assigned here. No Item Classes can be changed.'
    },
    changeReturnDate: {
      title: 'Change Return Date',
      text: 'You are about to change the return date for 1 transfer. Any tools you selected that were not assigned have been ignored. Are you sure you want to proceed? | You are about to change the return date for {count} transfers. Any tools you selected that were not assigned have been ignored. Are you sure you want to proceed?',
      label: 'Return Date',
      noTransferWarning: 'No Transfers Selected'
    },
    changeModel: {
      differntTrackingTypes: 'These items are of different tracking types and cannot be set to the same model',
      differentModelAssignment: 'Items cannot be re-assigned to a Model with a different tracking method.',
      changeKitsModel: 'Kits cannot be re-assigned to different Models.'
    },
    removeInventoryRecord: {
      title: 'Can not delete',
      message: 'You can only delete inventory records when your quantity on hand is zero.'
    },
    kit: {
      delete: {
        warningTitle: 'Delete Kit',
        warningMsg: 'This process will delete the kit and return the kit tools to inventory. Confirm deleting {description}?'
      },
      kitWarning: 'Item is currently associated with another kit. Would you like to remove the item from that kit and add to this kit?',
      kitWarningItemToTransfer: 'This item is in a kit. Would you like to remove the item from the kit or continue transfer?',
      labels: {
        0: 'Create New Kit Association',
        1: 'Add Items'
      }
    }
  },
  material: {
    title: 'Item Browser',
    modelBrowser: 'Material Model Browser',
    actionHeader: {
      0: {
        title: 'Add Item',
        icon: 'fas fa-plus'
      },
      2: {
        title: 'Print',
        icon: 'fas fa-print'
      },
      3: {
        title: 'Export Data',
        icon: 'fas fa-file-export'
      }
    },
    csvHeader: [
      {
        key: 'category',
        label: 'category'
      },
      {
        key: 'model',
        label: 'model'
      },
      {
        key: 'barcode',
        label: 'barcode'
      },
      {
        key: 'itemNumber',
        label: 'itemNumber'
      },
      {
        key: 'description',
        label: 'description'
      },
      {
        key: 'assignedTo',
        label: 'assignedTo'
      },
      {
        key: 'quantity',
        label: 'quantity'
      },
      {
        key: 'uom',
        label: 'uom'
      },
      {
        key: 'replacementCost',
        label: 'replacementCost'
      },
      {
        key: 'binId',
        label: 'bin'
      },
      {
        key: 'notes',
        label: 'notes'
      },
      {
        key: 'available',
        label: 'available',
        flag: 'isShowPickedItemState'
      },
      {
        key: 'pickedQuantity',
        label: 'allocated',
        flag: 'isShowPickedItemState'
      }
    ]
  },
  searchForm: {
    labels: {
      0: 'Advanced Search',
      1: 'Item No.',
      2: 'Type',
      3: 'Serial No',
      4: 'Wireless Tagged Items',
      5: 'Status',
      6: 'Class',
      7: 'Certification',
      8: 'Assignment',
      9: 'Department',
      10: 'Supervisor',
      11: 'Kit No.',
      12: 'Responsible Party',
      13: 'Number',
      14: 'Stock Location',
      15: 'Item No/Bar Code',
      16: 'Vin',
      17: 'License Plate Number',
      18: 'License Plate State'
    }
  },
  templateBrowser: 'Template Browser',
  showFullyAllocatedItems: 'Show Fully Allocated Items',
  print: {
    title: 'Report Options',
    reportLayout: 'Report Layout',
    availableReportOptions: 'Available Report Options...',
    groupBy: 'Group By',
    sortBy: 'Sort By',
    printOptions: 'Print Options',
    exportOptions: 'Export Options',
    all: 'All',
    selected: 'Selected',
    noItemsSelected: 'No items selected',
    counted: 'Counted',
    uncounted: 'Uncounted',
    printBarCodes: 'Print Bar Codes',
    printPhotos: 'Print Photos',
    UserDefined: 'User Defined',
    allFields: 'All Fields'
  }
}

export default itemBrowser
