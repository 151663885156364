const report = {
  customReports: {
    title: 'Custom Reports',
    noMsg: 'No Report Data',
    noFolderMsg: 'No Folder',
    tabs: {
      0: {
        title: 'New Report'
      },
      1: {
        title: 'Edit Report'
      },
      2: {
        title: 'Delete Report',
        key: 'DeleteCustomReport'
      },
      3: {
        title: 'Run Report'
      },
      4: {
        title: 'Export Report'
      },
      5: {
        title: 'New Folder'
      },
      6: {
        title: 'Send Feedback'
      }
    },
    actions: {
      0: 'Custom Reports',
      1: 'Recent',
      2: 'Created By Me',
      3: 'Private Reports',
      4: 'Public Reports',
      5: 'All Reports',
      6: 'Folder',
      7: 'All Folders',
      8: 'Created By Me',
      9: 'Shared With Me'
    },
    shareFolder: {
      title: 'Share Folder',
      labels: {
        0: 'Type',
        1: 'Recipients',
        2: 'Roles'
      },
      roles: {
        0: 'Users',
        1: 'All Users',
        2: 'Roles',
        3: 'All Supervisors'
      },
      tag: 'Share'
    },
    newReport: {
      title: 'Add Report',
      labels: {
        0: 'Type',
        1: 'Subtype'
      }
    },
    newFolder: {
      title: 'Add Folder',
      labels: 'Folder Name'
    },
    exportReport: {
      title: 'Export Report'
    },
    editReport: {
      actions: {
        1: {
          title: 'Save'
        },
        2: {
          title: 'Run'
        }
      },
      addOptions: {
        0: 'Add Column...',
        1: 'Add Filter...',
        2: 'Add Grouping...',
        3: 'Add Sorting...',
        4: 'Add Logic...'
      },
      operators: {
        0: 'equals',
        1: 'not equal to',
        2: 'less than',
        3: 'greater than',
        4: 'less or equal',
        5: 'greater or equal',
        6: 'contains',
        7: 'does not contain',
        8: 'starts with',
        9: 'ends with',
        10: 'is blank'
      },
      customReportLogic: {
        0: 'AND',
        1: 'OR',
        3: '(',
        4: ')',
        5: 'Add New',
        6: 'Filter Logic',
        7: 'Validate'
      },
      emptyMessage: 'Formula cannot be empty',
      filterOptions: {
        0: {
          label: 'All Time',
          value: 'AllTime:'
        },
        1: {
          label: 'Custom',
          value: 'Custom:{from}to{to}'
        },
        2: {
          'FISCAL YEAR': [
            {
              label: 'Current FY',
              value: 'FiscalYear:0'
            },
            {
              label: 'Previous FY',
              value: 'FiscalYear:-1'
            },
            {
              label: 'Previous 2 FY',
              value: 'FiscalYear:-2to-1'
            },
            {
              label: '2 FY Ago',
              value: 'FiscalYear:-2'
            },
            {
              label: 'Next FY',
              value: 'FiscalYear:1'
            },
            {
              label: 'Current and Previous FY',
              value: 'FiscalYear:-1to0'
            },
            {
              label: 'Current and Previous 2 FY',
              value: 'FiscalYear:-2to0'
            },
            {
              label: 'Current and Next FY',
              value: 'FiscaYear:0to1'
            }
          ],
          'FISCAL QUARTER': [
            {
              label: 'Current FQ',
              value: 'FiscalQuarter:0'
            },
            {
              label: 'Current and Next FQ',
              value: 'FiscalQuarter:0to1'
            },
            {
              label: 'Current and Previous FQ',
              value: 'FiscalQuarter:-1to0'
            },
            {
              label: 'Next FQ',
              value: 'FiscalQuarter:1'
            },
            {
              label: 'Previous FQ',
              value: 'FiscalQuarter:-1'
            },
            {
              label: 'Current and Next 3 FQ',
              value: 'FiscalQuarter:0to3'
            }
          ],
          'CALENDAR YEAR': [
            {
              label: 'Current CY',
              value: 'CalendarYear:0'
            },
            {
              label: 'Previous CY',
              value: 'CalendarYear:-1'
            },
            {
              label: 'Previous 2 CY',
              value: 'CalendarYear:-2to1'
            },
            {
              label: '2 CY Ago',
              value: 'CalendarYear:-2'
            },
            {
              label: 'Next CY',
              value: 'CalendarYear:1'
            },
            {
              label: 'Current and Previous CY',
              value: 'CalendarYear:-1to0'
            },
            {
              label: 'Current and Previous 2 CY',
              value: 'CalendarYear:-2to0'
            },
            {
              label: 'Current and Next CY',
              value: 'CalendarYear:0to1'
            }
          ],
          'CALENDAR QUARTER': [
            {
              label: 'Current CQ',
              value: 'CalendarQuarter:0'
            },
            {
              label: 'Current and Next CQ',
              value: 'CalendarQuarter:0to1'
            },
            {
              label: 'Current and Previous CQ',
              value: 'CalendarQuarter:-1to0'
            },
            {
              label: 'Next CQ',
              value: 'CalendarQuarter:1'
            },
            {
              label: 'Previous CQ',
              value: 'CalendarQuarter:-1'
            },
            {
              label: 'Current and Next 3 CQ',
              value: 'CalendarQuarter:0to3'
            }
          ],
          'CALENDAR MONTH': [
            {
              label: 'Last Month',
              value: 'CalendarMonth:-1'
            },
            {
              label: 'This Month',
              value: 'CalendarMonth:0'
            },
            {
              label: 'Next Month',
              value: 'CalendarMonth:1'
            },
            {
              label: 'Current and Previous Month',
              value: 'CalendarMonth:-1to0'
            },
            {
              label: 'Current and Next Month',
              value: 'CalendarMonth:0to1'
            }
          ],
          'CANLENDAR WEEK': [
            {
              label: 'Last Week',
              value: 'CalendarWeek:-1'
            },
            {
              label: 'This Week',
              value: 'CalendarWeek:0'
            },
            {
              label: 'Next Week',
              value: 'CalendarWeek:1'
            }
          ],
          DAY: [
            {
              label: 'Yesterday',
              value: 'Day:-1'
            },
            {
              label: 'Today',
              value: 'Day:0'
            },
            {
              label: 'Tomorrow',
              value: 'Day:1'
            },
            {
              label: 'Last 7 Days',
              value: 'Day:-6to0'
            },
            {
              label: 'Last 30 Days',
              value: 'Day:-29to0'
            },
            {
              label: 'Last 60 Days',
              value: 'Day:-59to0'
            },
            {
              label: 'Last 90 Days',
              value: 'Day:-89to0'
            },
            {
              label: 'Last 120 Days',
              value: 'Day:-119to0'
            },
            {
              label: 'Next 7 Days',
              value: 'Day:0to6'
            },
            {
              label: 'Next 30 Days',
              value: 'Day:0to29'
            },
            {
              label: 'Next 60 Days',
              value: 'Day:0to59'
            },
            {
              label: 'Next 90 Days',
              value: 'Day:0to89'
            },
            {
              label: 'Next 120 Days',
              value: 'Day:0to119'
            }
          ]
        }
      },
      groupSetting: {
        0: 'Detail Rows',
        1: 'Subtotals',
        2: 'Grand Total'
      },
      filterLabels: {
        0: 'Ascending',
        1: 'Descending',
        2: 'Exact',
        3: 'Range',
        4: 'Operator',
        5: 'Value',
        6: 'Start Date',
        7: 'End Date',
        8: 'is',
        9: 'True',
        10: 'False',
        12: 'Value Type',
        13: 'Enter Value',
        14: 'Current User Id',
        15: 'Name',
        16: 'Description',
        17: 'Folder',
        18: 'Private Report'
      },
      warning: 'Run report to see results, preview results limited',
      dataLimitInfo: 'Showing the first 20000 records. Export the full report to view all records.'
    },
    runReport: {
      actions: {
        0: {
          title: 'Edit'
        },
        1: {
          title: 'Export'
        }
      }
    },
    warning: {
      title: 'Warning',
      text: 'Please select a report.'
    },
    confirmDelete: {
      title: 'Confirm Delete',
      text: 'Are you sure you want to delete the Custom Report?'
    },
    longRunningReport: {
      title: 'Loading...',
      message: 'This report is taking longer than expected. You may navigate away from this page and a notification' +
      ' will appear in the message center when the report is ready.'
    },
    disabledEditText: 'Unable to edit report that is not saved. You can only edit reports that are saved',
    disabledExportText: 'Unable to export report that is not saved. You can only export reports that are saved'
  },
  scheduledReports: {
    title: 'Scheduled Reports',
    noMsg: 'There are no scheduled reports',
    placeholder: 'Search Scheduled Reports',
    scheduleDlg: {
      title: {
        0: 'Create Scheduled Report',
        1: 'Edit Scheduled Report',
        2: 'Delete Scheduled Report'
      },
      labels: {
        0: 'Report',
        1: 'Frequency',
        2: 'Daily',
        3: 'Weekly',
        4: 'Monthly',
        5: 'Time',
        6: 'Days of Week',
        7: 'Day of Week',
        8: 'Record Count',
        9: 'Only send report if record count is greater than',
        format: 'Format',
        html: 'HTML',
        pdf: 'PDF',
        csv: 'CSV'
      },
      days: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday'
      }
    }
  },
  cannedReports: {
    title: 'Report Chooser',
    actionHeader: {
      0: {
        title: 'Print Data',
        icon: 'fas fa-print'
      },
      1: {
        title: 'Export Data',
        icon: 'fas fi-file-export'
      }
    },
    reports: 'Reports',
    options: {
      title: 'Options',
      labels: {
        0: 'When printing labels, use',
        1: 'Item Number',
        2: 'Bar Code',
        3: 'Layout',
        4: 'Group By',
        5: 'Scheduled',
        6: 'Tools',
        7: 'Consumables',
        8: 'Equipment'
      },
      countStatus: {
        0: 'All',
        1: 'Closed',
        2: 'Open'
      },
      assetTypes: [
        {
          key: 'A',
          value: 'All'
        },
        {
          key: 'TQ',
          value: 'Tools'
        },
        {
          key: 'M',
          value: 'Consumables'
        }
      ],
      optionTexts: {
        10: 'All Employees and Locations',
        17: 'All Vendors',
        7: 'All Employees',
        9: 'All Locations',
        15: 'All Status Codes',
        24: 'All Requested Services'
      },
      ageLists: [
        {
          key: '45',
          value: '45 or more days'
        },
        {
          key: '90',
          value: '90 or more days'
        },
        {
          key: '180',
          value: '180 or more days'
        },
        {
          key: '-1',
          value: 'Any days'
        }
      ],
      expirationDays: [
        {
          key: 0,
          value: 'Expires in next 30 days'
        },
        {
          key: 1,
          value: 'Expires in next 90 days'
        },
        {
          key: 2,
          value: 'Currently under warranty'
        },
        {
          key: 3,
          value: 'Currently out of warranty'
        },
        {
          key: 4,
          value: 'All tools w/ warranty info'
        }
      ],
      chargeTypes: [
        {
          key: 0,
          value: 'Job Cost'
        },
        {
          key: 1,
          value: 'Billing'
        }
      ]
    },
    preview: {
      title: 'Preview',
      default: 'No Sample Available'
    }
  },
  customDashboard: {
    title: 'Custom Dashboards',
    tabs: {
      0: {
        title: 'New Dashboard'
      },
      1: {
        title: 'Edit Dashboard'
      },
      2: {
        title: 'New Folder'
      },
      3: {
        title: 'Select Module Dashboards'
      },
      4: {
        title: 'Copy Dashboard'
      }
    },
    actions: {
      0: 'Custom Dashboard',
      1: 'Recent',
      2: 'Created By Me',
      3: 'Private Dashboard',
      4: 'Public Dashboard',
      5: 'All Dashboard',
      6: 'Folder',
      7: 'All Folders',
      8: 'Created By Me',
      9: 'Shared With Me',
      10: 'Save',
      11: 'Update'
    },
    warningEdit: 'Select a dashboard to edit.',
    warningEditDashboard: 'Default not editable.',
    customDashboardNewFolder: {
      title: 'Add Folder',
      labels: 'Folder Name'
    },
    assignDashboards: {
      title: 'Assign Dashboards',
      labels: {
        0: 'System Dashboard',
        1: 'Service Dashboard',
        2: 'Pick Tickets & Transfers',
        3: 'Purchasing Dashboard'
      }
    },
    newDashboard: {
      title: 'New Dashboard'
    },
    editDashboard: {
      title: 'Edit Dashboard'
    },
    addItem: {
      0: {
        title: 'Back & Custom Dashboard',
        icon: 'fas fa-undo'
      }
    },
    saveDashboard: {
      title: 'Save Dashboard',
      updateTitle: 'Update Dashboard',
      labels: {
        0: 'Name',
        1: 'Description',
        2: 'Folder',
        3: 'Private Dashboard',
        4: 'Select Folder'
      }
    }
  }
}
export default report
