const taskBrowser = {
  task: {
    title: 'Task Browser',
    taskBrowserSelection: 'Task Assignment',
    tableHeader: {
      desktop: [
        {
          label: '',
          key: 'TaskId',
          type: 'check'
        },
        {
          label: 'Task Category',
          key: 'CategoryName',
          type: String,
          sortable: true
        },
        {
          label: 'State',
          key: 'State',
          type: String,
          sortable: true
        },
        {
          label: 'Subject',
          key: 'Subject',
          type: String,
          sortable: true
        },
        {
          label: 'Assigned To',
          key: 'AssignedTo',
          type: String,
          sortable: true
        },
        {
          label: 'Due By',
          key: 'DueBy',
          type: Date,
          sortable: true
        },
        {
          label: 'Source',
          key: 'Source',
          type: String,
          sortable: true
        },
        {
          label: 'Priority',
          key: 'PrioritiesName',
          type: String,
          sortable: true
        },
        {
          label: 'Status',
          key: 'StatusName',
          type: String,
          sortable: true
        },
        {
          label: 'Assigned By',
          key: 'CreatedBy',
          type: String,
          sortable: true
        },
        {
          label: 'Completed On',
          key: 'CompletedOn',
          type: Date,
          sortable: true
        },
        {
          label: 'Recurrence',
          key: 'Recurrence',
          type: String,
          sortable: true
        }
      ],
      mobile: [
        {
          label: '',
          key: 'TaskId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Assigned To',
              key: 'AssignedTo',
              type: String,
              sortable: true
            },
            {
              label: 'Due By',
              key: 'DueBy',
              type: Date,
              sortable: true
            },
            {
              label: 'Source',
              key: 'Source',
              type: String,
              sortable: true
            },
            {
              label: 'Priority',
              key: 'PrioritiesName',
              type: String,
              sortable: true
            },
            {
              label: 'Status',
              key: 'StatusName',
              type: String,
              sortable: true
            },
            {
              label: 'Assigned By',
              key: 'CreatedBy',
              type: String,
              sortable: true
            },
            {
              label: 'Completed On',
              key: 'CompletedOn',
              type: Date,
              sortable: true
            },
            {
              label: 'Recurrence',
              key: 'Recurrence',
              type: String,
              sortable: true
            },
            {
              label: 'State',
              key: 'State',
              type: String,
              sortable: true
            }
          ]
        }
      ]
    },
    filter: {
      0: 'All'
    },
    actionHeader: {
      0: {
        title: 'Add Task',
        icon: 'fas fa-plus'
      },
      1: {
        title: 'Edit Task',
        key: 'Edit Task',
        icon: 'fas fa-edit'
      },
      2: {
        title: 'Print',
        icon: 'fas fa-print'
      },
      3: {
        title: 'Export Data',
        icon: 'fas fa-file-export'
      }
    },
    modalHeader: {
      title: 'Create Task',
      subject: 'Subject',
      category: 'Task Category',
      toolWatchLocation: 'ToolWatch Location',
      otherLocation: 'Other Location',
      assignedTo: 'Assigned To',
      dueBy: 'Due By',
      priority: 'Priority',
      status: 'Status',
      notes: 'Notes',
      editTaskTitle: 'Edit Task',
      locationValidationMsg: 'ToolWatch Location is required',
      otherLocationValidationMsg: 'Other Location is required',
      dueByDateValidationMsg: 'Due Date is required for Recurring Tasks'
    },
    buttons: {
      switcherOff: 'My Tasks',
      switcherOn: 'All Tasks',
      recurrence: 'Recurrence',
      attach: 'Attachments',
      addAndNext: 'Add and Next',
      add: 'Add',
      cancel: 'Cancel',
      save: 'Save',
      saveTaskComplete: 'Save, Task Complete'
    },
    export: {
      noItemsSelected: 'No items selected'
    },
    messages: {
      saved: 'Task created successfully.',
      updated: 'Task saved successfully.'
    },
    recurrenceValue: {
      yes: 'Yes',
      no: 'No'
    },
    labels: {
      open: 'Open',
      closed: 'Closed',
      all: 'All'
    }
  }
}

export default taskBrowser
