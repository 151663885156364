const bleTable = {
  tableHeader: {
    sensors: {
      desktop: [
        {
          label: '',
          key: 'BleGatewayId',
          type: 'check'
        },
        {
          label: 'Gateway Assignment',
          key: 'LocationDescription',
          sortable: true
        },
        {
          label: 'Area',
          key: 'Area',
          sortable: true
        },
        {
          label: 'Type',
          key: 'GatewayType',
          sortable: true
        },
        {
          label: 'Manufacturer',
          key: 'Manufacturer',
          sortable: true
        },
        {
          label: 'Model',
          key: 'Model',
          sortable: true
        },
        {
          label: 'MAC address',
          key: 'MacAddress',
          sortable: true
        },
        {
          label: 'Created',
          type: Date,
          key: 'CreatedOn',
          sortable: true
        },
        {
          label: 'Action',
          key: 'BleGatewayId'
        }
      ],
      mobile:
      [
        {
          label: '',
          key: 'BleGatewayId',
          type: 'check'
        },
        {
          label: 'Action',
          key: 'BleGatewayId'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Gateway Assignment',
              key: 'LocationDescription',
              sortable: true
            },
            {
              label: 'Area',
              key: 'Area',
              sortable: true
            },
            {
              label: 'Type',
              key: 'GatewayType',
              sortable: true
            },
            {
              label: 'Manufacturer',
              key: 'Manufacturer',
              sortable: true
            },
            {
              label: 'Model',
              key: 'Model',
              sortable: true
            },
            {
              label: 'MAC address',
              key: 'MacAddress',
              sortable: true
            },
            {
              label: 'Created',
              type: Date,
              key: 'CreatedOn',
              sortable: true
            }
          ]
        }
      ]
    },
    tags: {
      desktop: [
        {
          label: '',
          key: 'BleTagId',
          type: 'check'
        },
        {
          label: 'Unique ID',
          key: 'UniqueId',
          sortable: true
        },
        {
          label: 'MAC Address',
          key: 'MacAddress',
          sortable: true
        },
        {
          label: 'Tag Model',
          key: 'MakeModel',
          sortable: true
        },
        {
          label: 'Serial Number',
          key: 'SerialNumber',
          sortable: true
        },
        {
          label: 'Last Scan',
          key: 'LastScan',
          type: Date,
          sortable: true
        },
        {
          label: 'Item ID',
          key: 'ItemNumber',
          sortable: true
        },
        {
          label: 'Item Brand',
          key: 'ItemManufacturer',
          sortable: true
        },
        {
          label: 'Item Model',
          key: 'ItemModel',
          sortable: true
        },
        {
          label: 'Item Description',
          key: 'ItemDescription',
          sortable: true
        }
      ],
      mobile:
      [
        {
          label: '',
          key: 'BleTagId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Unique ID',
              key: 'UniqueId',
              sortable: true
            },
            {
              label: 'MAC Address',
              key: 'MacAddress',
              sortable: true
            },
            {
              label: 'Tag Model',
              key: 'MakeModel',
              sortable: true
            },
            {
              label: 'Serial Number',
              key: 'SerialNumber',
              sortable: true
            },
            {
              label: 'Last Scan',
              key: 'LastScan',
              type: Date,
              sortable: true
            },
            {
              label: 'Deleted',
              key: 'Deleted',
              type: Boolean,
              sortable: true
            },
            {
              label: 'Item ID',
              key: 'ItemNumber',
              sortable: true
            },
            {
              label: 'Item Brand',
              key: 'ItemManufacturer',
              sortable: true
            },
            {
              label: 'Item Model',
              key: 'ItemModel',
              sortable: true
            },
            {
              label: 'Item Description',
              key: 'ItemDescription',
              sortable: true
            }
          ]
        }
      ]
    },
    tagsGPS: {
      desktop: [
        {
          label: '',
          key: 'BleTagId',
          type: 'check'
        },
        {
          label: 'Unique ID',
          key: 'UniqueId',
          sortable: true
        },
        {
          label: 'MAC Address',
          key: 'MacAddress',
          sortable: true
        },
        {
          label: 'Tag Model',
          key: 'MakeModel',
          sortable: true
        },
        {
          label: 'Serial Number',
          key: 'SerialNumber',
          sortable: true
        },
        {
          label: 'GPS',
          key: 'IsGPS',
          sortable: true
        },
        {
          label: 'Last Scan',
          key: 'LastScan',
          type: Date,
          sortable: true
        },
        {
          label: 'Item ID',
          key: 'ItemNumber',
          sortable: true
        },
        {
          label: 'Item Brand',
          key: 'ItemManufacturer',
          sortable: true
        },
        {
          label: 'Item Model',
          key: 'ItemModel',
          sortable: true
        },
        {
          label: 'Item Description',
          key: 'ItemDescription',
          sortable: true
        }
      ],
      mobile:
      [
        {
          label: '',
          key: 'BleTagId',
          type: 'check'
        },
        {
          label: 'Info',
          data: [
            {
              label: 'Unique ID',
              key: 'UniqueId',
              sortable: true
            },
            {
              label: 'MAC Address',
              key: 'MacAddress',
              sortable: true
            },
            {
              label: 'Tag Model',
              key: 'MakeModel',
              sortable: true
            },
            {
              label: 'Serial Number',
              key: 'SerialNumber',
              sortable: true
            },
            {
              label: 'GPS',
              key: 'IsGPS',
              sortable: true
            },
            {
              label: 'Last Scan',
              key: 'LastScan',
              type: Date,
              sortable: true
            },
            {
              label: 'Deleted',
              key: 'Deleted',
              type: Boolean,
              sortable: true
            },
            {
              label: 'Item ID',
              key: 'ItemNumber',
              sortable: true
            },
            {
              label: 'Item Brand',
              key: 'ItemManufacturer',
              sortable: true
            },
            {
              label: 'Item Model',
              key: 'ItemModel',
              sortable: true
            },
            {
              label: 'Item Description',
              key: 'ItemDescription',
              sortable: true
            }
          ]
        }
      ]
    },
    dropdownActions: [
      {
        title: 'Edit',
        actionName: 'editItem',
        permissions: []
      },
      {
        title: 'Delete',
        actionName: 'deleteItem',
        permissions: []
      }
    ],
    actionButton: 'Add Gateway',
    editOptionText: 'Edit Gateway',
    deleteOptionText: 'Delete Gateway',
    viewOptionText: 'View Gateway'
  },
  warnings: {
    0: 'Can only edit one Gateway at a time.'
  }
}

export default bleTable
