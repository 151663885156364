var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dropdown hide-d"},[_vm._m(0),_c('ul',{staticClass:"dropdown-menu animated fadeInRight m-t-xs"},_vm._l((_vm.actionHeaders),function(action,index){return _c('li',{key:'action_' + index},[(action[1].key ==='Print' && _vm.isPrintDisabled)?_c('a',{staticClass:"dropdown-item",class:{ 'isDisabled' : (_vm.isPrintDisabled) },attrs:{"title":_vm.$t('tickets.pick.subActionHeader.printTooltip')}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):(action[1].key ==='collaborate' && _vm.isShowCollaboration && _vm.isDisabledCollaboration)?_c('a',{staticClass:"dropdown-item",class:{ 'isDisabled' : (_vm.isDisabledCollaboration) }},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):(action[1].key ==='collaborate' && !_vm.isShowCollaboration)?_c('a',{staticClass:"dropdown-item",class:{ 'hide' : (true) }},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):(action[1].key ==='collaborate' && _vm.isShowCollaboration
        && !_vm.isDisabledCollaboration)?_c('a',{staticClass:"dropdown-item",on:{"click":function($event){$event.preventDefault();return _vm.selectAction(action, index)}}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title + (_vm.collaborationCount > 0 ? ' ('+ _vm.collaborationCount +')' : ''))+" ")]):(action[1].key ==='DeleteCustomReport')?_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.selectAction(action, index)}}},[(_vm.canDeleteCustomReports)?_c('div',[_vm._v(_vm._s(action[1].title))]):_vm._e()]):(action[1].title ==='Close Service Request' || action.title ==='Cerrar solicitud de servicio')?_c('a',{staticStyle:{"display":"flex","align-items":"center"},on:{"click":function($event){$event.preventDefault();return _vm.selectAction(action, index)}}},[_c('img',{staticStyle:{"width":"15px","height":"15px"},attrs:{"src":require("../../assets/icons/check.png")}}),_vm._v("   "),_c('div',[_vm._v(_vm._s(action[1].title))])]):(((action[1].title === 'Edit Work Order' ||
              action[1].title === 'Editar orden de trabajo')))?_c('a',{class:{ 'hide' : (!_vm.isClosedWorkOrder)},on:{"click":function($event){$event.preventDefault();if (_vm.isClosedWorkOrder) { _vm.selectAction(action, index) }}}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):_c('a',{staticClass:"dropdown-item",class:{ 'isDisabled' : (_vm.isDisabled && !action[1].isEditing ) && !(action[1].title === 'Back') },on:{"click":function($event){$event.preventDefault();return _vm.selectAction(action, index)}}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")])])}),0)]),_c('div',{staticClass:"row hide-m"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"tw-result-tabs reports"},[_c('div',{staticClass:"tabs-holder"},_vm._l((_vm.actionHeaders),function(action,index){return _c('div',{key:'action_' + index},[(action[1].key ==='Print' && _vm.isPrintDisabled)?_c('a',{class:{ 'isDisabled' : (_vm.isPrintDisabled) },attrs:{"title":_vm.$t('tickets.pick.subActionHeader.printTooltip')}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):(action[1].title ==='Create Count Ticket' && !_vm.canAddCountTicket)?_c('a',{class:{ 'hide' : (true)}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):(action[1].key ==='collaborate'
            && _vm.isShowCollaboration && _vm.isDisabledCollaboration)?_c('a',{class:{ 'isDisabled' : (_vm.isDisabledCollaboration)}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" "),_vm._m(1,true)]):(action[1].key ==='collaborate' && !_vm.isShowCollaboration)?_c('a',{class:{ 'hide' : (true)}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):(action[1].key ==='collaborate' && _vm.isShowCollaboration
            && !_vm.isDisabledCollaboration)?_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.selectAction(action, index)}}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title + (_vm.collaborationCount > 0 ? ' ('+ _vm.collaborationCount +')' : ''))+" ")]):(action[1].key ==='DeleteCustomReport')?_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.selectAction(action, index)}}},[(_vm.canDeleteCustomReports)?_c('div',[_vm._v(_vm._s(action[1].title))]):_vm._e()]):(action[1].title ==='Close Service Request' || action[1].title ==='Cerrar solicitud de servicio')?_c('a',{staticClass:"blur",staticStyle:{"display":"flex"},on:{"click":function($event){$event.preventDefault();return _vm.selectAction(action, index)}}},[_c('img',{staticStyle:{"width":"15px","height":"15px"},attrs:{"src":require("../../assets/icons/check.png")}}),_vm._v("    "),_c('div',[_vm._v(_vm._s(action[1].title))])]):((action[1].title ==='Add Task' ||
              action[1].title === 'Agregar tarea') && (!_vm.canTaskEdit && !_vm.canTaskAdd))?_c('a',{class:{ 'isDisabled' : (!_vm.canTaskEdit && !_vm.canTaskAdd)}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" "),_c('span',[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip:bottom",value:(_vm.$t('buttons.twButtonTooltip',
                  { buttonText: action[1].title, permission: ['TasksEdit','TasksAdd'] })),expression:"$t('buttons.twButtonTooltip',\n                  { buttonText: action[1].title, permission: ['TasksEdit','TasksAdd'] })",arg:"bottom"}],staticClass:"fas fa-lock",staticStyle:{"cursor":"default"}})])]):((action[1].title === 'Save' ||
              action[1].title === 'Guardar'))?_c('a',{class:{ 'isDisabled' : (_vm.isClosedWorkOrder) },attrs:{"disabled":_vm.isClosedWorkOrder},on:{"click":function($event){$event.preventDefault();if (!_vm.isClosedWorkOrder) { _vm.selectAction(action, index) }}}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):(((action[1].title === 'Edit Work Order' ||
              action[1].title === 'Editar orden de trabajo')))?_c('a',{class:{ 'hide' : (!_vm.isClosedWorkOrder)},on:{"click":function($event){$event.preventDefault();if (_vm.isClosedWorkOrder) { _vm.selectAction(action, index) }}}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):((action[1].title === 'Start Workflow' || action[1].title === 'Add Approval' ||
              action[1].title === 'Agregar aprobación' || action[1].title === 'Iniciar flujo de trabajo'))?_c('a',{class:{ 'isDisabled' : (!_vm.isDisabledStartWorkflow) },attrs:{"disabled":!_vm.isDisabledStartWorkflow},on:{"click":function($event){$event.preventDefault();if (_vm.isDisabledStartWorkflow) { _vm.selectAction(action, index) }}}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" "),((!_vm.isDisabledStartWorkflow && (action[1].title === 'Add Approval' ||
                action[1].title === 'Agregar aprobación') ) )?_c('span',[_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip:bottom",value:(_vm.$t('buttons.twButtonTooltip',
                  { buttonText: action[1].title, permission: 'TasksEdit' })),expression:"$t('buttons.twButtonTooltip',\n                  { buttonText: action[1].title, permission: 'TasksEdit' })",arg:"bottom"}],staticClass:"fas fa-lock",staticStyle:{"cursor":"default"}})]):_vm._e()]):((action[1].title === 'Edit' || action[1].title === 'Editar'))?_c('a',{class:{ 'isDisabled' : (!_vm.isEnableEditApprovalBtn) || _vm.isDisabled },style:({ cursor: (_vm.isDisabled && action[1].title === 'Edit') ? 'not-allowed' : 'pointer'}),attrs:{"disabled":"!isEnableEditApprovalBtn","title":_vm.getDisabledHoverText(_vm.isDisabled, 'Edit', action[1].title === 'Edit' || action[1].title === 'Editar')},on:{"click":function($event){$event.preventDefault();if (_vm.isEnableEditApprovalBtn) { _vm.selectAction(action, index) }}}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):((action[1].title === 'Delete' || action[1].title === 'Borrar'))?_c('a',{class:{ 'isDisabled' : (!_vm.isEnableDeleteBtn) },attrs:{"disabled":!_vm.isEnableDeleteBtn},on:{"click":function($event){$event.preventDefault();if (_vm.isEnableDeleteBtn) { _vm.selectAction(action, index) }}}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")]):_c('a',{class:{ 'isDisabled' : (_vm.isDisabled && !action[1].isEditing) && !(action[1].title === 'Back') },style:({ cursor: (_vm.isDisabled && action[1].title === 'Export') ? 'not-allowed' : 'pointer'}),attrs:{"title":_vm.getDisabledHoverText(_vm.isDisabled, 'Export', action[1].title === 'Export' || action[1].title === 'Exportar')},on:{"click":function($event){$event.preventDefault();return _vm.selectAction(action, index)}}},[_c('i',{class:action[1].icon}),_vm._v(" "+_vm._s(action[1].title)+" ")])])}),0)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{staticClass:"dropdown-toggle",attrs:{"data-toggle":"dropdown","href":"#"}},[_c('span',{staticClass:"block m-t-xs font-bold profile-name"},[_c('i',{staticClass:"fas fa-ellipsis-v"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('i',{staticClass:"fas fa-lock"})])}]

export { render, staticRenderFns }