<template>
  <div
    aria-hidden="true"
    aria-labelledby="dlgItemAttachments"
    class="modal fade tw-shipping-modal"
    :id="dialogId"
    data-backdrop="static"
    role="dialog"
    tabindex="-1">
    <div class="modal-dialog attachment" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-title">
            {{$t('catalog.attachment.title')}}
          </span>
          <button
            aria-label="Close" class="close" type="button" @click="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="alert alert-success" v-if="isSuccess" id="assignment-success-alert-attachments">
              {{$t('message.success.save')}}
            </div>
            <div class="alert alert-danger" v-if="isFailure" id="assignment-error-alert-attachments">
              {{$t('message.error.save')}}
            </div>
          </div>
          <table class="footable table toggle-arrow-tiny hide-d">
            <thead>
              <tr>
                <th class="text-center">
                  {{$t('catalog.attachment.labels.0')}}
                </th>
                <th class="text-center">
                  {{$t('catalog.attachment.labels.1')}}
                </th>
                <th class="text-center">
                  {{$t('catalog.attachment.labels.2')}}
                </th>
              </tr>
            </thead>
            <tbody style="position: relative">
              <div
                class="justify-content-center"
                :class="isLoading ? 'processing' : 'noProcessing'">
                <div class="spinner-border text-warning" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <template v-if="attachmentList.length">
                <tr
                  v-for="list in attachmentList"
                  :key="'record_' + list.AttachmentId"
                  :class="{selected: selectedAttachment === list}"
                  @click="selectedRowChange(list)">
                  <td class="text-center">{{list.Description}}</td>
                  <td class="text-center">{{getDate(list.ModifiedOn)}}</td>
                  <td class="text-center">{{list.ModifiedBy}}</td>
                </tr>
              </template>
              <template v-else-if="!isMultiSelect">
                <tr class="text-center">
                  <td colspan="3">
                    {{$t('message.attachmentMsg')}}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            @click="onClickAdd"
            :disabled="isLoading || viewOnly ||!this.hasAddEditPermission"
            class="btn close-btn"
            style="margin: 0px 5px 0px 5px"
            type="button">
            {{$t('buttons.attachment.0')}}
          </button>
          <button
            :disabled="!selectedAttachment ||
              isLoading ||
              this.isMultiSelect"
            @click="onClickView"
            class="btn close-btn"
            style="margin: 0px 5px 0px 5px"
            type="button">
            {{$t('buttons.attachment.1')}}
          </button>
          <button
            :disabled="!selectedAttachment ||
              isLoading ||
              viewOnly ||
              !this.hasAddEditPermission ||
              this.isMultiSelect"
            @click="onClickReplace"
            class="btn close-btn"
            style="margin: 0px 5px 0px 5px"
            type="button">
            {{$t('buttons.attachment.2')}}
          </button>
          <button
            :disabled="!selectedAttachment ||
              isLoading ||
              viewOnly ||
              !this.hasAddEditPermission ||
              this.isMultiSelect"
            @click="onClickRename"
            class="btn close-btn"
            style="margin: 0px 5px 0px 5px"
            type="button">
            {{$t('buttons.attachment.3')}}
          </button>
          <button
            :disabled="!selectedAttachment ||
             isLoading ||
              viewOnly ||
              !this.hasAddEditPermission ||
              this.isMultiSelect"
            @click="onClickDelete"
            class="btn close-btn"
            style="margin: 0px 5px 0px 5px"
            type="button">
            {{$t('buttons.attachment.4')}}
          </button>
          <button
            :disabled="!isSelectedFileType ||
             isLoading ||
              !this.hasAddEditPermission ||
               this.isMultiSelect"
            @click="onClickSaveAs"
            class="btn close-btn"
            style="margin: 0px 5px 0px 5px"
            type="button">
            {{$t('buttons.attachment.5')}}
          </button>
        </div>
      </div>
      <ModalDialog
        ref="dlgAttachmentSelect"
        :title="selectDialogTitle"
        :onOkay="onAttachDialogOk"
        :disabledOk="!locationSelected"
        :maxWidth="'800px'"
      >
        <template v-slot:body>
          <fieldset style="border: 1px solid">
            <legend style="font-weight: bold; font-size: large;
              width: auto; margin-left: 10px;
              padding: 0px 10px 0px 10px">
              {{$t('catalog.attachment.labels.3')}}
            </legend>
            <div style="padding: 5px 10px 5px 30px">
              <input id="attachmentTypeFile" type="radio"
                name="attachmentType"
                :value="eAttachType.File"
                v-model="attachType"
                :disabled="isReplaceMode && isWebType">
              <label for="attachmentTypeFile" style="margin-left: 5px;">
                {{$t('catalog.attachment.labels.4')}}
              </label>
              <input id="attachmentTypeWeb" type="radio"
                name="attachmentType"
                :value="eAttachType.Web"
                style="margin-left: 20px"
                v-model="attachType"
                :disabled="isReplaceMode && isFileType">
              <label for="attachmentTypeWeb" style="margin-left: 5px;">
                {{$t('catalog.attachment.labels.5')}}
              </label>
            </div>
          </fieldset>
          <fieldset style="border: 1px solid; margin-top: 20px; min-height: 180px;">
            <legend style="font-weight: bold; font-size: large; width: auto;
              margin-left: 10px; padding: 0px 10px 0px 10px;">
              {{$t('catalog.attachment.labels.6')}}
            </legend>
            <div style="padding: 10px 10px 5px 30px">
              <div class="form-group row">
                <div class="col-lg-3">
                  <label class="col-form-label">
                    {{selectLabel}}
                  </label>
                </div>
                <div :class="locColClass">
                  <form-group
                    :validator="$v.location"
                  >
                    <input type="text"
                      class="form-control"
                      style="width:100%; margin-bottom: 5px;"
                      @input="$v.location.$touch()"
                      :class="{ error: $v.location.$error }"
                      v-model="location"
                      :disabled="isFileType" />
                  </form-group>
                </div>
                <div class="col-lg-2"
                  v-show="isFileType">
                  <button type="button"
                    class="btn"
                    style="font-size: 15px; color: white;"
                    @click="openBrowser">
                    {{$t('catalog.attachment.labels.7')}}
                  </button>
                </div>
              </div>
              <input id="inputAttachmentFile"
                type="file"
                ref="attachmentFile"
                @change="onSelectAttachmentFile"
                hidden />
              <div class="form-group row">
                <div class="col-lg-3">
                  <label class="col-form-label">
                    {{$t('catalog.attachment.labels.8')}}
                  </label>
                </div>
                <div class="col-lg-9">
                  <input class="form-control"
                    v-model.trim="description" />
                </div>
              </div>
            </div>
          </fieldset>
        </template>
      </ModalDialog>
      <ModalDialog
        ref="dlgAttachmentRename"
        :title="'Rename Attachment'"
        :onOkay="onRenameDialogOk"
        :disabledOk="!canSaveRename">
        <template v-slot:body>
          <div class="form-group row">
            <div class="col-lg-3">
              <label class="col-form-label">
                {{$t('catalog.attachment.labels.9')}}
              </label>
            </div>
            <div class="col-lg-9">
              <input
                class="form-control"
                id="attachmentRenameInput"
                type="text"
                ref="renameInput"
                v-model="attachRename" >
            </div>
          </div>
        </template>
      </ModalDialog>
      <ConfirmDialog ref="deleteConfirm"
        :title="$t('message.attachment.title')"
        :message="$t('message.attachment.text')"
        @confirmResult="onConfirmDelete">
      </ConfirmDialog>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { uuid } from 'vue-uuid'
import { format } from 'date-fns'
import ModalDialog from '../../components/shared/ModalDialog.vue'
import ConfirmDialog from '../../components/shared/ConfirmDialog.vue'
import $ from 'jquery'
import { generateUniqueId, errorHandler, processFileDownload, urlWithOptionalProtocol } from '../../helpers/utils.js'

export default {
  name: 'ItemAttachments',
  components: {
    ModalDialog,
    ConfirmDialog
  },
  props: {
    onClose: {
      type: Function,
      required: false,
      default: null
    },
    isCloneSafety: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      eAttachType: { File: 1, Web: 2 },
      eMode: { Add: 1, Replace: 2 },

      itemId: null,
      itemViewData: null,
      attachmentList: [],
      attachmentsTable: null,
      selectedAttachment: null,
      viewOnly: false,
      isMultiSelect: false,

      // Add dialog
      mode: null,
      attachType: null,
      inputFileName: null,
      inputFileBase64: null,
      location: null,
      description: null,
      isSuccess: false,
      isFailure: false,

      // Rename dialog
      attachRename: '',
      isLoading: false,
      dialogId: 'dlgItemsAttachment' + generateUniqueId()
    }
  },
  validations: {
    location: {
      isValidUrl () {
        return this.isWebType ? urlWithOptionalProtocol(this.location) : true
      }
    }
  },
  mounted () {
    $('#assignment-success-alert-attachments').hide()
    $('#assignment-error-alert-attachments').hide()
  },
  methods: {
    async open (selectedItemId, viewOnly = false) {
      $('#' + this.dialogId).modal('show')
      this.selectedAttachment = null
      this.itemId = selectedItemId
      if (Array.isArray(selectedItemId) &&
        selectedItemId.length > 1) {
        this.isMultiSelect = true
      }
      this.viewOnly = viewOnly
      this.clear()
      if (!await this.loadAttachments()) {
        this.showError('An error occurred loading attachments.')
      }
    },
    async loadAttachments () {
      let returnVal = false
      this.isLoading = true
      const params = JSON.stringify({
        recordId: '\'' + this.itemId + '\''
      })
      try {
        if (!this.isMultiSelect) {
          if (!this.isCloneSafety) {
            const { data } = await axios.post('/api/core/AttachmentsService/Get', params)
            this.attachmentList = data.Body
          } else {
            this.attachmentList = this.cloneAttachmentsData
          }
        }
        this.isLoading = false
        returnVal = true
      } catch (error) {
        this.isLoading = false
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
      return returnVal
    },
    clear () {
      // this.attachmentList.length
    },
    getDate (dateString) {
      if (!dateString) {
        return ''
      }
      return format(new Date(Date.parse(dateString)), 'MMM d, yyyy')
    },
    onClickAdd () {
      this.inputFileName = ''
      this.mode = this.eMode.Add
      this.attachType = this.eAttachType.File
      this.location = null
      this.description = ''
      this.$refs.dlgAttachmentSelect.open()
    },
    async onClickView () {
      if (this.selectedAttachment.IsLink) {
        if (this.selectedAttachment.ResourcePath != null &&
          this.selectedAttachment.ResourcePath.length > 0) {
          window.open(this.selectedAttachment.ResourcePath, '_blank')
        } else {
          this.showError('No link was provided.')
        }
      } else {
        this.isLoading = true
        let attachmentData
        if (!this.isCloneSafety) {
          attachmentData = await this.getAttachmentData(this.selectedAttachment.AttachmentId)
        } else {
          attachmentData = this.b64toBlob(this.selectedAttachment.Base64File,
            this.selectedAttachment.MimeType)
        }
        this.isLoading = false
        if (attachmentData != null) {
          processFileDownload(attachmentData,
            this.selectedAttachment.ResourcePath,
            this.selectedAttachment.MimeType, true)
        } else {
          this.showError('An error occurred retrieving attachment.')
        }
      }
    },
    onClickReplace () {
      this.inputFileName = ''
      this.mode = this.eMode.Replace
      this.attachType = this.selectedAttachment.IsLink
        ? this.eAttachType.Web : this.eAttachType.File
      this.location = null
      this.description = this.selectedAttachment.Description
      this.$refs.dlgAttachmentSelect.open()
    },
    onClickRename () {
      this.attachRename = this.selectedAttachment.Description
      this.$refs.dlgAttachmentRename.open()
    },
    onClickDelete () {
      this.$refs.deleteConfirm.open()
    },
    selectedRowChange (list) {
      if (!this.selectedAttachment) {
        this.selectedAttachment = list
      } else if (this.selectedAttachment.AttachmentId === list.AttachmentId) {
        this.selectedAttachment = null
      } else {
        this.selectedAttachment = list
      }
    },
    async onAttachDialogOk () {
      if (this.isReplaceMode && !this.deleteAttachment(this.selectedAttachment)) {
        this.showError('An error occurred deleting existing attachment.')
        return
      }
      if (this.$v.$invalid) {
        this.$v.location.$touch()
        return
      }
      const attachment = {}
      attachment.File = this.$refs.attachmentFile.files[0]
      attachment.ResourcePath = this.location
      attachment.RecordId = this.itemId
      attachment.Description = this.description == null ? '' : this.description
      attachment.IsLink = !this.isFileType

      const newAttachment = await this.addAttachment(attachment)
      if (newAttachment !== null) {
        this.attachmentList.push(newAttachment)
        this.selectedAttachment = newAttachment
        this.isSuccess = true
        this.showSuccess(this.isReplaceMode
          ? this.$t('message.success.attachmentReplacement')
          : this.$t('message.success.attachmentAdded'))
      } else {
        this.isLoading = false
        this.isFailure = true
        this.showError('An error occured saving attachment')
      }
      return true
    },
    async addAttachment (request) {
      let returnVal = null
      this.isLoading = true
      try {
        const entityParams = JSON.stringify({
          orgId: '\'' + localStorage.getItem('OrgId') + '\'',
          entityId: '\'' + localStorage.getItem('UserId') + '\''
        })
        const { data: entityData } = await axios.post('/api/core/EntitiesService/GetEntityDtoById', entityParams)
        const user = entityData.Body
        const attachmentId = uuid.v4()
        var resourcePathUrl = ''
        if (request.IsLink) {
          try {
            if (new URL(request.ResourcePath) != null) {
              resourcePathUrl = request.ResourcePath
            }
          } catch (_) {
            resourcePathUrl = 'http://' + request.ResourcePath
          }
        }
        const attachment = {
          AttachmentId: attachmentId,
          OrganizationId: localStorage.getItem('OrgId'),
          Description: request.Description,
          RecordId: request.RecordId,
          IsLink: request.IsLink,
          CreatedOn: new Date(),
          CreatedById: localStorage.getItem('UserId'),
          CreatedBy: user.Description,
          ModifiedOn: new Date(),
          ModifiedById: localStorage.getItem('UserId'),
          ModifiedBy: user.Description,
          ResourcePath: request.IsLink ? resourcePathUrl : attachmentId + '.' + request.File.name.split('.').pop(),
          MimeType: request.IsLink ? '' : request.File.type,
          RecordIds: null
        }
        if (this.isMultiSelect) {
          attachment.RecordIds = request.RecordId
          attachment.RecordId = request.RecordId[0]
        }
        const params = {
          attachment: JSON.stringify(attachment),
          fileStream: request.IsLink ? null : this.inputFileBase64
        }
        if (!this.isCloneSafety) {
          axios.post('/api/core/AttachmentsService/Add', params)
        } else {
          attachment.Base64File = request.IsLink ? '' : this.inputFileBase64
        }
        this.isLoading = false
        returnVal = attachment
        this.$emit('addAttachment', attachmentId)
      } catch (error) {
      }
      return returnVal
    },
    b64toBlob (b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)

        const byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
      }
      const blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
    async deleteAttachment (attachment) {
      let returnVal = false
      this.isLoading = true
      try {
        if (!this.isCloneSafety) {
          const parm = {
            attachmentIds: JSON.stringify([attachment.AttachmentId])
          }
          await axios.post('/api/core/AttachmentsService/DeleteByIds', parm)
        }
        this.isLoading = false
        const index = this.attachmentList.indexOf(attachment)
        if (index > -1) {
          this.attachmentList.splice(index, 1)
        }
        if (this.isCloneSafety) {
          this.$store.commit('addSafetyAttachments', this.attachmentList)
        }
        this.$emit('deleteAttachment', attachment.AttachmentId)
        this.selectedAttachment = null
        returnVal = true
      } catch (error) {
        this.isLoading = false
        if (error.response) {
          errorHandler(this.$toast, error.response.statusText, error)
        }
      }
      return returnVal
    },
    async renameAttachment (attachment) {
      let returnVal = true
      this.isLoading = true
      try {
        const attachments = []
        attachments.push(attachment)
        const params = {
          attachments: JSON.stringify(attachments)
        }
        if (!this.isCloneSafety) {
          await axios.post('/api/core/AttachmentsService/Update', params)
        } else {
          const listData = this.cloneAttachmentsData
          for (const attachment of listData) {
            const attachmentIndex = attachment.findIndex(obj =>
              obj.AttachmentId == attachment.AttachmentId)
            if (attachmentIndex < 0 || this.isCloneSafety) {
              listData.push(attachment)
              this.$store.commit('addSafetyAttachments', listData)
            } else {
              this.cloneAttachmentsData[attachmentIndex].ResourcePath = attachment.ResourcePath
              this.cloneAttachmentsData[attachmentIndex].IsLink = attachment.IsLink
              this.cloneAttachmentsData[attachmentIndex].Description = attachment.Description
            }
          }
        }
        this.isLoading = false
        returnVal = true
      } catch (error) {
      }
      return returnVal
    },
    onSelectAttachmentFile () {
      const file = this.$refs.attachmentFile.files[0]
      this.inputFileName = file.name
      let fileName = this.inputFileName
      if (fileName.lastIndexOf('\\') >= 0) {
        fileName = fileName.substring(fileName.lastIndexOf('\\') + 1)
      }
      this.location = fileName

      if (!this.description || this.description.length === 0) {
        let desc = fileName
        if (desc.lastIndexOf('.') >= 0) {
          desc = desc.substring(0, desc.lastIndexOf('.'))
        }
        this.description = desc
      }
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.inputFileBase64 = reader.result.split(',')[1]
      }
    },
    async onClickSaveAs () {
      this.isLoading = true
      let attachmentData
      if (!this.isCloneSafety) {
        attachmentData = await this.getAttachmentData(this.selectedAttachment.AttachmentId)
      } else {
        attachmentData = this.b64toBlob(this.selectedAttachment.Base64File, this.selectedAttachment.MimeType)
      }
      this.isLoading = false
      if (attachmentData !== null) {
        processFileDownload(attachmentData,
          this.selectedAttachment.ResourcePath,
          this.selectedAttachment.MimeType)
      } else {
        this.showError('An error occurred saving attachment.')
      }
    },
    openBrowser () {
      this.$refs.attachmentFile.click()
    },
    showSuccess (message) {
      $('#assignment-success-alert-attachments').text(message && message.length > 0 ? message : 'Changes saved successfully.')
      $('#assignment-success-alert-attachments').fadeTo(2000, 500).slideUp(500, function () {
        $('#assignment-success-alert-attachments').slideUp(500)
      })
      setTimeout(() => {
        this.isSuccess = false
      }, 5000)
    },
    showError (message) {
      $('#assignment-error-alert-attachments').text(message && message.length > 0 ? message : 'Failed to save changes.')
      $('#assignment-error-alert-attachments').fadeTo(2000, 500).slideUp(500, function () {
        $('#assignment-error-alert').slideUp(500)
      })
      setTimeout(() => {
        this.isFailure = false
      }, 5000)
    },
    async onConfirmDelete (confirm) {
      if (confirm) {
        if (await this.deleteAttachment(this.selectedAttachment)) {
          this.showSuccess('The attachment was deleted successfully.')
        } else {
          this.showError('An error occurred trying to delete the attachment.')
        }
      }
    },
    async onRenameDialogOk () {
      const oldValue = this.selectedAttachment.Description
      this.selectedAttachment.Description = this.attachRename
      if (await this.renameAttachment(this.selectedAttachment)) {
        // this.selectedAttachment.Description = this.attachRename
        this.showSuccess('Renamed Successfully')
        this.isLoading = false
      } else {
        this.selectedAttachment.Description = oldValue
        this.isLoading = false
        this.showError('An error occurred renaming attachment.')
      }
    },
    async getAttachmentData (attachmentId) {
      let returnData = null
      try {
        const param = JSON.stringify({ attachmentId: JSON.stringify(attachmentId) })
        const { data } = await axios.post('/api/core/AttachmentsService/GetStream', param, { responseType: 'blob' })
        returnData = data
      } catch (error) {
      }
      return returnData
    },
    async close () {
      if (!this.onClose || await this.onClose()) {
        $('#' + this.dialogId).modal('hide')
        setTimeout(() => {
          $('body').addClass('modal-open')
        }, 500)
      }
    }
  },
  computed: {
    selectDialogTitle () {
      return this.mode === this.eMode.Add ? 'Add Attachment' : 'Replace Attachment'
    },
    selectLabel () {
      return this.attachType === this.eAttachType.File ? 'Import from' : 'URL http://'
    },
    isFileType () {
      return this.attachType === this.eAttachType.File
    },
    isWebType () {
      return this.attachType === this.eAttachType.Web
    },
    locationSelected () {
      return this.location != null && this.location.length > 0
    },
    isReplaceMode () {
      return this.mode === this.eMode.Replace
    },
    canSaveRename () {
      return this.selectedAttachment != null &&
      this.selectedAttachment.Description != this.attachRename
    },
    isSelectedFileType () {
      return this.selectedAttachment && !this.selectedAttachment.IsLink
    },
    locColClass () {
      return this.isFileType ? 'col-lg-7' : 'col-lg-9'
    },
    userPermissions () {
      return this.$store.getters.userPermission
    },
    hasAddEditPermission () {
      return this.userPermissions.includes('MaterialDetailEdit') ||
        this.userPermissions.includes('ToolDetailEdit')
    },
    cloneAttachmentsData () {
      return this.$store.getters.SafetyAttachmentsData
    }
  },
  watch: {
    attachType () {
      this.location = null
      this.inputFileName = null
      this.description = null
    }
  }
}
</script>
<style>
:root {
  --secondary-color: #D09433;
  --warning-hover-disabled-color: #ebb241;
}
#dlgItemAttachments {
  z-index: 100000!important;
}
.modal-dialog.attachment {
  top: 52%!important;
  left: 46%!important;
  overflow-y: auto!important;
}
th {
  background: #F5F5F6;
  color: #212529;
  border: none;
}
.processing {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200000;
  background-color: transparent;
  /* background: rgba(0,0,0, 0.25); */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.noProcessing {
  display: none!important;
}
/* .modal-footer .btn {
  margin: 5px!important;
  height: 30px!important;
} */
.modal-footer .btn.close-btn {
  color: #495760!important;
}
.footable tbody > tr.selected > td {
  background-color: var(--warning-hover-disabled-color)!important;
  color: white;
}
.footable tbody > tr:hover {
  cursor: pointer;
}
</style>
<style scoped>
@media (max-width: 500px) {
  .tw-shipping-modal .modal-footer {
    flex-wrap: wrap;
  }
}
.modal{
  overflow: hidden !important;
}
</style>
